import React from 'react';
import { Typography, Space, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatureBenefits from "../FeatureBenefits";
import NewCaseButton from "./NewCaseButton";
import Demo from './Demo';

const { Title } = Typography;

const bodyStyle = {
  textAlign: 'center',
  padding: '0px',
  paddingBottom: '20px'
}

const spaceStyle = {
  width: '100%',
};

const CaseEmptyState = ({ showModal }) => {
  const { t } = useTranslation();

  return (
    <Card
      styles={{ body: bodyStyle }}
      data-testid="case-empty-state-container"
    >
      <Space direction="vertical" size="small" style={spaceStyle}>
        <Title level={3} style={{marginTop: '20px'}}>{t('case_empty_state.title')}</Title>
        <FeatureBenefits featureKey='case_empty_state'/>
        <div className='case-actions' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <NewCaseButton onCaseCreated={() => {}} />
          <Demo />
        </div>
      </Space>
    </Card>
  );
};

export default CaseEmptyState;
