import React from 'react';
import HoverableTag from '../../components/HoverableTag';

const QuestionButton = ({ text, onClick, ...props }) => {
  return (
    <HoverableTag
      text={text}
      onClick={onClick}
      {...props}
    />
  );
};

export default QuestionButton;