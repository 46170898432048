import React, { useState } from 'react';
import { useRecorder } from '../../hooks/useRecorder';
import { useTimer } from '../../hooks/useTimer';
import { usePreventRecordingAbandon } from '../../hooks/usePreventRecordingAbandon';
import { formatTime } from '../../utils/formatTime';
import { AudioOutlined, CloseOutlined } from "@ant-design/icons";
import { ConfigProvider, Divider, Typography, Modal, Button, Tooltip } from "antd";
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';
import RecordingButton from './RecordingButton';
import FinalizeButton from './FinalizeButton';
import Demo from './Demo';
import NoteTypeSelector from './NoteTypeSelector';
import completeSession from '../../utils/session/completeSession';
import deleteSession from '../../utils/session/deleteSession';
import MicrophoneError from './MicrophoneError';
import SessionRecorderNotepad from './SessionRecorderNotepad';

const { Text } = Typography;

// _initialChunksCount should be used only in tests, to simulate the number of chunks that have been recorded
const SessionRecorder = ({ _initialChunksCount = 0, showDemo = false }) => {
  const supportedAudioFormats = require('../../utils/supportedAudioFormats').default;
  const audioMimeType = supportedAudioFormats.filter(MediaRecorder.isTypeSupported)[0];
  const [showNoteTypeSelector, setShowNoteTypeSelector] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [isSessionCompleted, setIsSessionCompleted] = useState(false);
  const { t } = useTranslation();

  const {
    isRecording,
    hasStarted,
    hasPaused,
    toggleRecording,
    endRecording,
    sessionUuid,
    totalChunks,
    problemType,
    problemStats
  } = useRecorder(audioMimeType, _initialChunksCount);

  const timer = useTimer(isRecording, hasStarted);
  const { addPreventListener, removePreventListener } = usePreventRecordingAbandon(isRecording, sessionUuid);

  const handleCloseNoteTypeSelector = () => {
    setShowNoteTypeSelector(false);
  };

  const displayWarningRecTooShort = () => {
    displayWarningModal(
      t('session_recorder.errors.too_short_title'),
      t('session_recorder.errors.too_short_body'),
      'rec-too-short-warning-modal'
    );
  };

  const displayWarningCompleteFailed = () => {
    displayWarningModal(
      t('session_recorder.errors.complete_title'),
      t('session_recorder.errors.complete_body'),
      'complete-failed-warning-modal'
    );
  };

  const displayWarningModal = (title, body, elemId) => {
    Modal.warn({
      title: title,
      content: <div data-testid={elemId}>{body}</div>,
    });
    console.error(title, body);
  };

  const handleFinalize = async (event) => {
    setIsFinalizing(true);
    if (totalChunks < 1) {
      console.log("handleFinalize: session too short");
      event.preventDefault();
      displayWarningRecTooShort();
      setIsFinalizing(false);
      return;
    }

    const success = await completeSession(sessionUuid, totalChunks, problemStats);
    if (success) {
      console.log("handleFinalize: complete request sent successfully");
      await endRecording();
      logEvent('SessionRecorder','Complete')
      setShowNoteTypeSelector(true);
      setIsSessionCompleted(true); // Mark the session as completed to start polling
    } else {
      console.log("handleFinalize: complete request failed");
      event.preventDefault();
      displayWarningCompleteFailed();
    }
    setIsFinalizing(false);
  };

  const handleDiscard = () => {
    logEvent('SessionRecorder', 'DiscardOpen');

    Modal.confirm({
      title: t('note_type_selector.confirm_title'),
      content: t('note_type_selector.confirm_context'),
      okText: t('note_type_selector.confirm_ok_text'),
      okType: 'danger',
      cancelText: t('note_type_selector.confirm_no_text'),
      onOk: async () => {
        // Temporarily remove the prevent listener to allow the user to close the recording modal
        removePreventListener();
        const success = await deleteSession(sessionUuid);
        logEvent('SessionRecorder', 'DiscardConfirm');
        if (success) {
          Modal.destroyAll();
          window.location.href = '/sessions';
        } else {
          // Re-adding the listener if the deletion was unsuccessful
          addPreventListener();
        }
      },
    });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            // display the discard [x] button as grey
            colorError: 'grey'
          },
        },
      }}
    >
      <Modal
        open={hasStarted}
        title={
          <div>
            { hasStarted && isRecording ? <AudioOutlined className='iconSpacing' /> : "" }
            { hasStarted && isRecording ? t('session_recorder.title') : t('session_recorder.paused_title') }
            <div>
              <Text type='secondary' style={{ fontWeight: 'normal'}}>{ t('session_recorder.subtitle') }</Text>
            </div>
          </div>
        }
        footer={
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', paddingBottom: '5px' }}>
            <MicrophoneError type={problemType} />
            <RecordingButton isRecording={isRecording} hasStarted={hasStarted} toggleRecording={toggleRecording} />
            <FinalizeButton
              handleFinalize={handleFinalize}
              hasStarted={hasStarted}
              hasPaused={hasPaused}
              isFinalizing={isFinalizing}
            />
            <SessionRecorderNotepad sessionUuid={sessionUuid} /> {/* Insert the Notepad component */}
          </div>
        }
        closable={false}
        className='session-recorder--modal'
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div className="session-recorder--timer">{formatTime(timer)}</div>
        </div>
        <Tooltip title={t('session_recorder.fields.discard_tooltip')}>
          <Button
            icon={<CloseOutlined />}
            shape="circle"
            danger
            onClick={handleDiscard}
            style={{ position: 'absolute', top: 10, right: 10 }}
          />
        </Tooltip>
      </Modal>
      {!isRecording && !hasStarted &&
        <RecordingButton
          isRecording={isRecording}
          hasStarted={hasStarted}
          toggleRecording={toggleRecording}
        />
      }
      {showNoteTypeSelector && (
        <NoteTypeSelector
          sessionUuid={sessionUuid}
          visible={showNoteTypeSelector}
          onClose={handleCloseNoteTypeSelector}
        />
      )}
      {showDemo &&
        <>
          <Divider plain>{t('sign_in.fields.or')}</Divider>
          <Demo />
        </>}
    </ConfigProvider>
  );
};

export default SessionRecorder;
