import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col, Layout, Grid } from 'antd';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'react-i18next';

import RecentQueries from '../components/query/RecentQueries';
import QueryDetailsCard from '../components/query/QueryDetailsCard';
import usePageMeta from '../hooks/usePageMeta';
import useQueryPolling from '../hooks/useQueryPolling';
import BackLink from '../components/BackLink';

import { handleError } from '../utils/errorHandling';
import { debounce } from 'lodash';
import QuerySearchAutocomplete from "../components/query/QuerySearchAutocomplete";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const QueryDetails = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const { query, loading, error } = useQueryPolling(uuid, 1000);

  // This state & debounced function drive reloadTrigger for RecentQueries
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const handleReloadRecentQueries = debounce(() => {
    setReloadTrigger((prev) => prev + 1);
  }, 500);

  // Log and handle fetch errors if any
  if (error) {
    Bugsnag.notify(new Error(`loadQuery error: ${JSON.stringify(error)}`));
    handleError(t('query_details.errors.load'));
  }

  // Use your page meta hook (sets <title>, etc.)
  usePageMeta(query?.getQuestion?.());

  return (
    <Content className="page">
      {!query && loading ? (
        <Skeleton active />
      ) : query ? (
        <Row gutter={[32, 32]}>
          {/* Show back button only on mobile */}
          { !screens.md && (
            <div style={{marginLeft: '10px'}}>
              <BackLink to='/queries/new' label={t('common.back')}/>
            </div>
          )}
          {/* Sidebar: Search + Recent Queries */}
          <Col xs={24} md={8} lg={8}>
            <QuerySearchAutocomplete/>
            <RecentQueries
              activeQueryUuid={uuid}
              reloadTrigger={reloadTrigger}
            />
          </Col>

          {/* Query Details Card */}
          <Col xs={24} md={16} lg={16}>
            <QueryDetailsCard
              query={query}
              onReloadRecentQueries={handleReloadRecentQueries}
            />
          </Col>
          {/* Show back button only on mobile */}
          { !screens.md && (
            <div style={{marginLeft: '10px'}}>
              <BackLink to='/queries/new' label={t('common.back')}/>
            </div>
          )}
        </Row>
      ) : null}
    </Content>
  );
};

export default QueryDetails;
