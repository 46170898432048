import { useState, useEffect } from 'react';
import fetchQuery from '../utils/query/fetchQuery';

export default function useQueryPolling(uuid, pollIntervalMs = 1000) {
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    let intervalId;

    async function loadQuery() {
      try {
        setLoading(true);
        const queryPresenter = await fetchQuery(uuid);
        if (!isMounted) return;

        setQuery(queryPresenter);
        setLoading(false);

        // If query is completed, stop further polling
        if (queryPresenter.isCompleted()) {
          clearInterval(intervalId);
        }
      } catch (err) {
        if (!isMounted) return;
        setError(err);
        setLoading(false);
      }
    }

    // Initial load
    loadQuery();

    // Set up polling
    intervalId = setInterval(() => {
      loadQuery();
    }, pollIntervalMs);

    // Cleanup on unmount
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [uuid, pollIntervalMs]);

  return { query, loading, error };
}
