import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Testimonial from './Testimonial';

// Import testimonial images
import testimonialImageB from '../assets/testimonials/b.webp';
import testimonialImageC from '../assets/testimonials/c.webp';

const Testimonials = () => {
  const { t } = useTranslation();
  const [randomTestimonial, setRandomTestimonial] = useState(null);
  
  // Select a random testimonial on component mount
  useEffect(() => {
    const testimonials = [
      {
        name: t('testimonials.dra_jimenez.name'),
        role: t('testimonials.dra_jimenez.role'),
        image: testimonialImageB,
        intro: t('testimonials.dra_jimenez.intro'),
        quote: t('testimonials.dra_jimenez.quote'),
      },
      {
        name: t('testimonials.dra_caicedo.name'),
        role: t('testimonials.dra_caicedo.role'),
        image: testimonialImageC,
        intro: t('testimonials.dra_caicedo.intro'),
        quote: t('testimonials.dra_caicedo.quote'),
      }
    ];
    
    // Select a random testimonial
    const randomIndex = Math.floor(Math.random() * testimonials.length);
    setRandomTestimonial(testimonials[randomIndex]);
  }, [t]); // Add t as a dependency to re-run when language changes

  return (
    <div className="testimonials-container">
      <div className="testimonials-section">
        {randomTestimonial && (
          <div className="testimonials-item">
            <Testimonial {...randomTestimonial} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;