import React from 'react';
import CaseTextModal from './CaseTextModal';
import createCaseTextEntry from '../../utils/case/createCaseTextEntry';
import {useTranslation} from 'react-i18next';
import {logEvent} from "../../analytics";

const CaseTextUpdate = ({ isVisible, onClose, caseUuid, onUpdateSuccess }) => {
  const { t } = useTranslation();

  const handleCreateCaseTextEntry = async (textEntryContent) => {
    const response = await createCaseTextEntry(caseUuid, textEntryContent);
    logEvent('CaseUpdate', 'Text');
    if (response && response.uuid) {
      onUpdateSuccess(response.uuid); // Notify parent with the created text entry
    } else {
      throw new Error('Failed to create case text entry');
    }
  };

  return (
    <CaseTextModal
      isVisible={isVisible}
      onClose={onClose}
      title={t('case_text_entry.title')}
      submitButtonText={t('common.submit')}
      placeholderText={t('case_text_entry.placeholder')}
      onSubmit={handleCreateCaseTextEntry}
      minLength={10}
      dataTestId="case-text-update-modal"
    />
  );
};

export default CaseTextUpdate;