import React, { useState, useEffect } from 'react';
import { Collapse, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import CaseUpdatedTag from './CaseUpdatedTag';
import CaseChangesPresenter from '../../presenters/CaseChangesPresenter';

const { Panel } = Collapse;
const { Text } = Typography;

// --- Style Helpers ---
const paragraphBaseStyle = {
  padding: '2px 4px',
  borderRadius: '4px'
};

const listItemBaseStyle = {
  marginBottom: '0.6rem',
  padding: '2px 4px',
  borderRadius: '4px'
};

const getParagraphStyle = (highlightColor) => ({
  ...paragraphBaseStyle,
  backgroundColor: highlightColor,
});

const getListItemStyle = (highlightColor) => ({
  ...listItemBaseStyle,
  backgroundColor: highlightColor,
});

// --- Utility Functions ---
const buildGetter = (key) =>
  `get${key
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')}`;

const getInitialActiveKeys = (sections) =>
  sections.reduce((acc, section) => {
    acc[section.key] = section.key;
    return acc;
  }, {});

const getItemCount = (fields, caseData) =>
  fields.filter((field) => {
    const getter = buildGetter(field.key);
    const value = caseData?.[getter]?.();
    return value && value !== 'N/A' && value.trim() !== '';
  }).length;

const getHighlightColor = (fieldIndex, highlight) => {
  if (!highlight) return 'transparent';
  if (fieldIndex === 0) return '#f0fdf4'; // Very light green for first field
  if (fieldIndex === 1) return '#fdfde6'; // Soft yellow for second field
  return 'transparent';
};

// --- Component: Renders a single field ---
const renderField = (field, fieldIndex, caseData, hideEmptySubsection, highlight, t) => {
  const getter = buildGetter(field.key);
  const value = caseData?.[getter]?.();
  if (hideEmptySubsection && (!value || value === 'N/A')) return null;

  const highlightColor = getHighlightColor(fieldIndex, highlight);
  const content = value && value !== 'N/A' ? value : 'N/A';

  return (
    <React.Fragment key={field.key}>
      <Text type="secondary">{t(`case_details.fields.${field.key}`)}</Text>
      <div style={{ marginTop: '4px' }}>
        {content === 'N/A' ? (
          <p style={{ ...getParagraphStyle(highlightColor), display: 'block' }}>N/A</p>
        ) : (
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => (
                <p {...props} style={getParagraphStyle(highlightColor)} />
              ),
              li: ({ node, ...props }) => (
                <li {...props} style={getListItemStyle(highlightColor)} />
              )
            }}
          >
            {content}
          </ReactMarkdown>
        )}
      </div>
    </React.Fragment>
  );
};

// --- Main Component ---
const CaseDetailsCollapse = ({
                               caseData,
                               sections,
                               changeset,
                               defaultExpanded = false,
                               hideEmptySubsection = false,
                               highlight = false
                             }) => {
  const { t } = useTranslation();
  const [activeKeys, setActiveKeys] = useState({});
  const changesPresenter = new CaseChangesPresenter(caseData, changeset);

  useEffect(() => {
    if (defaultExpanded) {
      setActiveKeys(getInitialActiveKeys(sections));
    }
  }, [defaultExpanded, sections]);

  const handleCollapseChange = (sectionKey) => (keys) => {
    setActiveKeys(prevState => ({ ...prevState, [sectionKey]: keys }));
  };

  return (
    <>
      {sections.map((section, sectionIndex) => {
        const itemCount = getItemCount(section.fields, caseData);
        const isSectionUpdated = section.fields.some(field =>
          changesPresenter.isUpdated(field.key)
        );

        const title = (
          <span>
            {section.title} {isSectionUpdated && <CaseUpdatedTag />}
          </span>
        );

        return (
          <Collapse
            key={section.key}
            activeKey={activeKeys[section.key]}
            onChange={handleCollapseChange(section.key)}
            expandIconPosition="end"
            style={{
              background: 'white',
              border: '1px solid #f0f0f0',
              marginBottom: sectionIndex < sections.length - 1 ? '4px' : '0',
              opacity: itemCount === 0 ? 0.4 : 1
            }}
          >
            <Panel
              header={title}
              key={section.key}
              style={{
                background: 'white',
                border: 'none',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                overflow: 'hidden'
              }}
            >
              {section.fields.map((field, fieldIndex) =>
                renderField(field, fieldIndex, caseData, hideEmptySubsection, highlight, t)
              )}
            </Panel>
          </Collapse>
        );
      })}
    </>
  );
};

export default CaseDetailsCollapse;
