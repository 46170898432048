import { getCurrentUser } from './userStorage';

const supportedFeature = (featureName) => {
  switch (featureName) {
    case "Calls":
      return isCallsSupported();
    default:
      return false;
  }
};

// Private functions
const isCallsSupported = () => {
  const currentUser = getCurrentUser();
  // Recorded Session test number: +1 256 888 9188
  const supportedCountries = ['AR', 'CL', 'CO', 'MX'];

  // if survey_country is supported
  if (currentUser.survey_country) {
    return supportedCountries.some((countryCode) =>
      currentUser.survey_country?.includes(countryCode)
    );
  }

  // if calls_country is supported
  if (currentUser.calls_country) {
    return supportedCountries.some((countryCode) =>
      currentUser.calls_country?.includes(countryCode)
    );
  }
  // by default, return false
  return false;
};

export default supportedFeature;