import React from 'react';
import { List } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const CaseDocumentsList = ({ documents }) => {
  const { t } = useTranslation();

  if (!documents || documents.length === 0) {
    return null;
  }

  return (
    <List
      size="small"
      dataSource={documents}
      renderItem={(doc) => (
        <List.Item>
          <List.Item.Meta
            avatar={<PaperClipOutlined style={{ fontSize: '16px' }} />}
            title={
              <a href={doc.getUrl()} target="_blank" rel="noopener noreferrer" style={{ color: doc.hasProblem() ? 'red' : '#1890ff' }}>
                {doc.getDocName(t)}
              </a>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default CaseDocumentsList;