import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { Card, Button, Grid, Avatar } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CaseQueries from './CaseQueries';
import { useCaseQuerySuggestedQuestions } from '../../hooks/useCaseQuerySuggestedQuestions';

const { useBreakpoint } = Grid;

const CaseChatFixed = forwardRef(({ caseUuid, caseQueries, casePresenter }, ref) => {
  const { t } = useTranslation();
  const [viewState, setViewState] = useState('minimized');
  const [chatInput, setChatInput] = useState('');
  const screens = useBreakpoint();
  const isMobile = !screens.md;
  const suggestedQuestions = useCaseQuerySuggestedQuestions(casePresenter);

  // New ref for the embedded CaseQueries instance.
  const queriesRef = useRef(null);

  const boxShadow = '0 4px 12px rgba(0, 0, 0, 0.15)';

  useImperativeHandle(
    ref,
    () => ({
      openChatWithInput: (input) => {
        setChatInput(input);
        setViewState(isMobile ? 'fullscreen' : 'medium');
      },
    }),
    [isMobile]
  );

  const getContainerStyle = () => {
    if (viewState === 'fullscreen') {
      return {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 2000,
      };
    }
    return {
      position: 'fixed',
      bottom: 0,
      right: 20,
      zIndex: 2000,
    };
  };

  const cardStyle =
    viewState === 'fullscreen'
      ? { width: '100%', height: '100%', boxShadow }
      : isMobile
        ? { width: '70vw', height: '70vh', borderRadius: '10px 10px 0 0', boxShadow }
        : { width: 'calc(50vw - 20px)', height: '80vh', borderRadius: '10px 10px 0 0', boxShadow };

  const renderHeaderExtra = () => {
    if (isMobile) {
      return (
        <div style={{ display: 'flex', gap: 8 }}>
          <Button size="small" icon={<MinusOutlined />} onClick={() => setViewState('minimized')} />
        </div>
      );
    } else {
      if (viewState === 'medium') {
        return (
          <div style={{ display: 'flex', gap: 8 }}>
            <Button size="small" icon={<FullscreenOutlined />} onClick={() => setViewState('fullscreen')} />
            <Button size="small" icon={<MinusOutlined />} onClick={() => setViewState('minimized')} />
          </div>
        );
      } else if (viewState === 'fullscreen') {
        return (
          <div style={{ display: 'flex', gap: 8 }}>
            <Button size="small" icon={<FullscreenExitOutlined />} onClick={() => setViewState('medium')} />
            <Button size="small" icon={<MinusOutlined />} onClick={() => setViewState('minimized')} />
          </div>
        );
      }
    }
  };

  const chatTitle =
    caseQueries && caseQueries.length > 0
      ? t('case_queries.chat_title') + ` (${caseQueries.length})`
      : t('case_queries.chat_title');

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setViewState('minimized');
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  // New effect: when the chat box is opened, trigger a scroll-to-bottom on the embedded feed.
  useEffect(() => {
    if (viewState !== 'minimized' && queriesRef.current && typeof queriesRef.current.scrollToBottom === 'function') {
      setTimeout(() => {
        queriesRef.current.scrollToBottom();
      }, 0);
    }
  }, [viewState]);

  return (
    <>
      {/* Minimized view */}
      <div
        data-testid="fixed-chat-minimized"
        style={{
          display: viewState === 'minimized' ? 'flex' : 'none',
          position: 'fixed',
          bottom: 0,
          right: 30,
          backgroundColor: '#e6f4ff',
          border: '1px solid #91caff',
          color: '#0958d9',
          padding: '16px 24px',
          borderRadius: '10px 10px 0 0',
          alignItems: 'center',
          cursor: 'pointer',
          maxWidth: '50vw',
          zIndex: 2000,
          fontSize: '16px',
          boxShadow,
          lineHeight: '1.5',
        }}
        onClick={() => {
          setViewState(isMobile ? 'fullscreen' : 'medium');
        }}
      >
        <Avatar src="https://img.icons8.com/plasticine/100/light-on.png" />
        <span style={{ marginLeft: 8 }}>{chatTitle}</span>
      </div>

      {/* Expanded view */}
      <div
        data-testid="fixed-chat-open"
        style={{ display: viewState === 'minimized' ? 'none' : 'block', ...getContainerStyle() }}>
        <Card
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src="https://img.icons8.com/plasticine/100/light-on.png" />
              <span style={{ fontSize: 16 }}>{t('case_queries.chat_title')}</span>
            </div>
          }
          extra={renderHeaderExtra()}
          style={cardStyle}
          styles={{
            body: {
              padding: 16,
              height: '100%',
              overflow: 'auto',
            }
          }}
        >
          <CaseQueries
            ref={queriesRef}
            embedded
            casePresenter={casePresenter}
            caseUuid={caseUuid}
            caseQueries={caseQueries}
            chatInput={chatInput}
            setChatInput={setChatInput}
          />
        </Card>
      </div>
    </>
  );
});

export default CaseChatFixed;
