import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { handleAuthSuccess } from '../utils/authSuccess';
import { handleError } from '../utils/errorHandling';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../analytics';

const MagicLinkSignIn = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const server_host = process.env.REACT_APP_SERVER_HOST;

  useEffect(() => {
    const token = searchParams.get('token');
    const redirectTo = searchParams.get('redirect_to') || '/';

    if (!token) {
      handleError('No magic link token provided');
      navigate('/sign_in');
      return;
    }

    axios
      .get(`${server_host}/users/magic_link_logins/verify?token=${token}`, {
        headers: {
          // Override default Authorization header if present
          Authorization: ''
        }
      })
      .then((response) => {
        const { token: jwt, refresh_token: refreshToken } = response.data;
        // On success, store tokens and redirect to the provided path
        logEvent('MagicLink', 'SignIn');
        handleAuthSuccess(jwt, refreshToken, redirectTo);
      })
      .catch((error) => {
        handleError(error);
        navigate('/sign_in?error=1');
      })
  }, [navigate, searchParams, t]);

  return null;
};

export default MagicLinkSignIn;
