import React, { useState } from 'react';
import {Upload, Modal, Spin, Typography, Grid} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import attachDocumentToCase from '../../utils/case/attachDocumentToCase';
import { toast } from 'react-hot-toast';
import { logEvent } from '../../analytics';
import {Bugsnag} from "../../bugsnag";

const { Text, Paragraph } = Typography;
const { Dragger } = Upload;
const { useBreakpoint } = Grid;

const CaseDocumentsDrop = ({ caseUuid, onDocumentAttached }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [uploading, setUploading] = useState(false);

  // Validate file format and show a toast error if unsupported.
  const beforeUpload = (file) => {
    console.log('beforeUpload file:', file);
    const allowedMimeTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    const allowedExtensions = ['pdf', 'docx', 'xlsx'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
``
    if (file.type) {
      if (!allowedMimeTypes.includes(file.type)) {
        Bugsnag.leaveBreadcrumb(`Unsupported file type: ${file.type}`)
        toast.error(t('case_documents.errors.unsupported_format'));
        return false; // Cancel upload.
      }
    } else {
      if (!allowedExtensions.includes(fileExtension)) {
        Bugsnag.leaveBreadcrumb(`Unsupported file type: ${file.type}`)
        toast.error(t('case_documents.errors.unsupported_format'));
        return false;
      }
    }
    return true;
  };

  // Custom upload logic.
  const customRequest = async ({ file, onSuccess, onError }) => {
    setUploading(true);
    try {
      const response = await attachDocumentToCase(caseUuid, file);
      logEvent('CaseDocuments', 'DropUpload');
      if (onDocumentAttached) {
        onDocumentAttached(response.uuid);
      }
      onSuccess('ok');
    } catch (error) {
      onError(error);
    } finally {
      setUploading(false);
    }
  };

  // Remove "accept" so that all dropped files are processed by beforeUpload.
  const uploadProps = {
    name: 'case[document]',
    beforeUpload,
    customRequest,
    showUploadList: false,
    onChange: (info) => {
      console.log('onChange info:', info);
    },
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Dragger {...uploadProps}>
        <div style={{ textAlign: 'center' }}>
          <InboxOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
          <Paragraph>
            {screens.md ? t('case_documents.fields.drag_and_drop') : t('case_documents.fields.drag_and_drop_mobile') }
          </Paragraph>
          <Text type="secondary">
            {t('case_documents.fields.drag_and_drop_subtitle')}
          </Text>
        </div>
      </Dragger>
      <Modal open={uploading} footer={null} closable={false} centered>
        <div style={{ textAlign: 'center' }}>
          <Text type="secondary">{t('case_documents.fields.uploading')}</Text>
          <Spin />
        </div>
      </Modal>
    </div>
  );
};

export default CaseDocumentsDrop;