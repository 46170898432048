import authRequest from '../authRequest';
import {handleError} from "../errorHandling";

const attachDocumentToCase = async (caseUuid, documentFile) => {
  try {
    const formData = new FormData();
    formData.append('case[document]', documentFile); // Nest the document under "case"

    const response = await authRequest(`/cases/${caseUuid}/attach_document`, {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status !== 200) {
      throw new Error('Attaching document to case failed');
    }

    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export default attachDocumentToCase;