import React from 'react';
import { useCaseQuerySuggestedQuestions } from '../../hooks/useCaseQuerySuggestedQuestions';
import HoverableTag from '../../components/HoverableTag';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const CaseQuerySuggestedQuestions = React.memo(({ onQuestionClick, casePresenter, inChat = false }) => {
  const { t } = useTranslation();
  const sampledQuestions = useCaseQuerySuggestedQuestions(casePresenter);
  console.log("sampledQuestions", sampledQuestions);

  if (!inChat) {
    return (
      <Collapse
        defaultActiveKey={['1']}
        expandIconPosition="end"
        style={{
          background: 'white',
          border: '1px solid #f0f0f0',
          marginBottom: '4px'
        }}
      >
        <Panel
          header={t('case_queries.suggested_questions_title')}
          key="1"
          style={{
            background: 'white',
            border: 'none',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              paddingBottom: '10px',
            }}
          >
            {sampledQuestions.map((question, index) => {
              const trimmed = question.short_question.trim();
              return (
                <HoverableTag
                  key={index}
                  data-testid={`suggested-question-${index}`}
                  onClick={() => onQuestionClick(question.long_question)}
                  text={trimmed}
                />
              );
            })}
          </div>
        </Panel>
      </Collapse>
    );
  }

  // When inChat is true, render the questions without collapse.
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        paddingBottom: '50px',
      }}
    >
      {sampledQuestions.map((question, index) => {
        const trimmed = question.short_question.trim();
        return (
          <HoverableTag
            key={index}
            data-testid={`suggested-question-${index}`}
            onClick={() => onQuestionClick(question.long_question)}
            text={trimmed}
          />
        );
      })}
    </div>
  );
});

export default CaseQuerySuggestedQuestions;