import authRequest from '../authRequest';

const createMagicLink = async () => {
  try {
    const response = await authRequest('/magic_links', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      throw new Error('Error creating magic link');
    }
    // Assuming the server returns { magic_link_url: "https://..."}
    const { magic_link_url } = response.data;
    return magic_link_url;
  } catch (error) {
    console.error('Error creating magic link:', error);
    throw error;
  }
};

export default createMagicLink;
