import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const VersionChecker = () => {
  const { t } = useTranslation();
  const [newVersion, setNewVersion] = useState(null);

  useEffect(() => {
    let currentVersion;

    // Get interval from environment variable (in minutes) or default to 1440 (24 hours)
    const checkIntervalMinutes = parseInt(
      process.env.REACT_APP_VERSION_CHECK_INTERVAL || '1440',
      10
    );
    const checkIntervalMs = checkIntervalMinutes * 60 * 1000;

    const checkVersions = async () => {
      try {
        // First get bundled version (no cache bust)
        if (!currentVersion) {
          const res = await fetch('/version.json');
          currentVersion = (await res.json()).version;
          console.log(`VersionChecker: local version ${currentVersion}`);
        }

        // Then check for updates (with cache bust)
        const { version: serverVersion } = await (
          await fetch(`/version.json?t=${Date.now()}`)
        ).json();
        console.log(`VersionChecker: cloud version ${serverVersion}`);
        if (serverVersion !== currentVersion) {
          console.log(`VersionChecker: update is available!`);
          setNewVersion(serverVersion);
        }
      } catch (error) {
        console.log('VersionChecker failed:', error);
      }
    };

    // Initial check
    checkVersions();

    console.log(`VersionChecker: will check again in ${checkIntervalMinutes} minutes`);
    // Set up daily check
    const timer = setInterval(checkVersions, checkIntervalMs);
    return () => clearInterval(timer);
  }, []); // Empty dependency array = runs once on mount

  const handleReload = () => {
    console.log('VersionChecker: reloading...');
    window.location.href = window.location.origin + '?v=' + Date.now();
  };

  return (
    <Modal
      title={t('version_checker.title')}
      open={!!newVersion}
      onOk={handleReload}
      onCancel={() => setNewVersion(null)}
      okText={t('version_checker.confirm')}
      cancelText={t('version_checker.cancel')}
      closable={false}
      maskClosable={false}
    >
      <p>{t('version_checker.body')}</p>
    </Modal>
  );
};

export default VersionChecker;