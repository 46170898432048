import React, { useState } from 'react';
import { Tag } from 'antd';

const HoverableTag = ({ text, onClick, highlight = false, ...props }) => {
  const [hover, setHover] = useState(false);
  const baseStyle = {
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '15px',
    marginBottom: '8px',
    whiteSpace: 'normal',
    height: 'auto',
    maxWidth: '100%',
    fontSize: '14px',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  };
  const hoverColor = highlight ? '#e0c6ff' : '#cceeff';
  const hoverStyle = hover
    ? { backgroundColor: hoverColor, boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }
    : {};
  return (
    <Tag
      color= {highlight ? "purple" : "blue"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      style={{ ...baseStyle, ...hoverStyle }}
      {...props}
    >
      {text}
    </Tag>
  );
};

export default HoverableTag;