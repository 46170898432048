import React from 'react';
import { Card, Typography, Grid } from 'antd';
import QueryForm from './QueryForm';
import { useTranslation } from 'react-i18next';
import QueryRelatedQuestions from "./QueryRelatedQuestions";

const { useBreakpoint } = Grid;

const QueryFollowup = ({ queryUuid, query, onReloadRecentQueries }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <Card
      style={{ marginTop: '20px' }}
      title={t('query_followup.title')}
    >
      {query.getRelatedQuestions() && (
        <QueryRelatedQuestions relatedQuestions={query.getRelatedQuestions()} previousQueryUuid={query.getUUID()} />
      )}
      <div style={{ marginTop: '20px' }}>
        <QueryForm onSubmit={onReloadRecentQueries} previousQueryUuid={queryUuid} placeholder={ t('query_followup.fields.placeholder')} shouldSubmit={false}/>
      </div>
    </Card>
  );
};

export default QueryFollowup;
