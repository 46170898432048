import React from 'react';
import { Button, Form } from 'antd';

const FormSubmit = ({ label, isSubmitting }) => {
    return (
        <Form.Item>
            <Button
              style={{ borderColor: 'grey'}}
              type="default"
              htmlType="submit"
              loading={isSubmitting}
              data-testid="form-submit"
            >
              {label}
            </Button>
        </Form.Item>
    );
};

export default FormSubmit;
