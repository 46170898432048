import { toast } from "react-hot-toast";

export const getErrorMessage = (error) => {
    if (typeof error === 'string') {
        return error;
    } else if (error?.response?.data?.error) {
        return error.response.data.error;
    } else if (error?.response?.data?.errors) {
        return error.response.data.errors;
    } else if (error?.error) {
        return error.error;
    } else if (error?.message) {
        return error.message;
    }
    return null;
};

export const handleError = (error) => {
    const message = getErrorMessage(error);

    if (message) {
        console.error(message);
        toast.error(message);
    } else {
        console.warn('Unknown error format:', error);
    }
}

export const handleWarning = (message) => {
    if (message) {
        toast(message, {
            icon: '❗',
        });
        console.warn(message);
    }
}
