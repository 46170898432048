import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link, useSearchParams } from 'react-router-dom';
import authRequest from '../utils/authRequest';
import { getToken } from '../utils/tokenStorage';
import { handleAuthSuccess } from "../utils/authSuccess";
import {handleError, handleWarning} from '../utils/errorHandling';
import { useTranslation } from 'react-i18next';
import { Form, Typography, Divider } from 'antd';
import EmailInput from '../components/forms/EmailInput';
import PasswordInput from '../components/forms/PasswordInput';
import FormSubmit from "../components/forms/FormSubmit";
import GoogleSignIn from '../components/GoogleSignIn';

const SignIn = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchParams] = useSearchParams();
    // get the original path the user tried to access
    const originPath = location.state?.from?.pathname || '/';

    useEffect(() => {
        const token = getToken();
        if (token) {
            navigate(originPath, { replace: true });
        }
        // Check for the 'error' query parameter
        const errorParam = searchParams.get('error');
        if (errorParam) {
            setTimeout(() => handleWarning(t('sign_in.errors.auth_expired')), 500);
        }
    }, [navigate, searchParams, originPath]);

    const handleSignIn = async (values) => {
        setIsSubmitting(true);
        try {
            const response = await authRequest('/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user: {
                        email: values.email,
                        password: values.password,
                    },
                }),
            });

            if (response.status === 200) {
                const authHeader = response.headers.get('Authorization');
                if (authHeader) {
                    // Auth header comes in the form "Bearer token"
                    const authToken = authHeader.split(' ')[1];
                    const refreshToken = response.data?.refresh_token; // Extract refresh token from response
                    handleAuthSuccess(authToken, refreshToken, originPath);
                } else {
                    handleError(t('sign_in.errors.auth_not_found'))
                }
            } else {
                handleError(response?.data?.error);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
      <div className='public-page'>
          <div className='form-sizing'>
              <Form form={form} onFinish={handleSignIn} className="signin-form">
                  <Title level={3} style={{marginBottom: '24px'}}>{t('sign_in.title')}</Title>
                  <Form.Item>
                      <GoogleSignIn origin={originPath} />
                  </Form.Item>
                  <Divider plain>{t('sign_in.fields.or')}</Divider>
                  <EmailInput />
                  <PasswordInput />
                  <FormSubmit label={t('sign_in.fields.sign_in')} isSubmitting={isSubmitting} />
              </Form>
              <p>
                  {t('sign_in.fields.no_account')} <Link to="/sign_up">{t('sign_in.fields.sign_up_here')}</Link>
              </p>
              <p>
                  {t('sign_in.fields.forgot_password')} <Link to="/reset_password">{t('sign_in.fields.reset')}</Link>
              </p>
          </div>
      </div>
    );
};

export default SignIn;
