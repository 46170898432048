import React from 'react';
import UpdateCaseButton from './UpdateCaseButton';
import UploadDocumentButton from './UploadDocumentButton';

const CaseActions = ({ caseUuid, onCaseUpdated, onDocumentAttached }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'right', gap: '8px' }}>
      <UpdateCaseButton caseUuid={caseUuid} onCaseUpdated={onCaseUpdated} />
      <UploadDocumentButton caseUuid={caseUuid} onDocumentAttached={onDocumentAttached} />
    </div>
  );
};

export default CaseActions;