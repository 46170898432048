import ReactGA from 'react-ga4';
import * as amplitude from '@amplitude/analytics-browser';
import { Identify } from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { plugin as engagementPlugin } from '@amplitude/engagement-browser';
import { getCurrentUser } from './utils/userStorage';
import { ENVIRONMENT } from './environmentVariables';

const isProduction = ENVIRONMENT === 'production';

export const initGA = () => {
    if (isProduction) {
        try {
            ReactGA.initialize('G-3W1L765NTY');
            const currentUser = getCurrentUser();
            if (currentUser) {
                ReactGA.set({
                    userId: currentUser.uuid,
                    user_properties: {
                        created_at: currentUser.created_at,
                        timezone: currentUser.timezone,
                        locale: currentUser.locale,
                        internal: currentUser.internal,
                        survey_role: currentUser.survey_role,
                        survey_specialty: currentUser.survey_specialty,
                        survey_workplace: currentUser.survey_workplace,
                        survey_country: currentUser.survey_country,
                        survey_completed: currentUser.survey_completed,
                    },
                });
            }
        } catch (error) {
            console.error('Failed to initialize Google Analytics', error);
        }
    }
};

export const initAmplitude = () => {
    if (!isProduction) return;

    const isStorageAccessible = () => {
        try {
            const key = '__amplitude_storage_test__';
            localStorage.setItem(key, '1');
            localStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    };

    // If localStorage isn't accessible, skip Amplitude initialization.
    if (!isStorageAccessible()) {
        console.warn('localStorage not available - skipping Amplitude initialization');
        return;
    }

    try {
        amplitude.add(sessionReplayPlugin());
        amplitude.add(engagementPlugin());

        amplitude.init('519da1c7165dce0cc95356bcf58b6c18');

        const currentUser = getCurrentUser();
        if (currentUser) {
            // Use the UUID as the user identifier.
            amplitude.setUserId(currentUser.uuid);
            const identify = new Identify();
            // Set additional user properties using the Identify API
            identify.set('created_at', currentUser.created_at);
            identify.set('timezone', currentUser.timezone);
            identify.set('locale', currentUser.locale);
            identify.set('internal', currentUser.internal);
            identify.set('survey_role', currentUser.survey_role);
            identify.set('survey_specialty', currentUser.survey_specialty);
            identify.set('survey_workplace', currentUser.survey_workplace);
            identify.set('survey_country', currentUser.survey_country);
            identify.set('survey_completed', currentUser.survey_completed);
            amplitude.identify(identify);
        }
    } catch (error) {
        console.error('Failed to initialize Amplitude Analytics', error);
    }
};

const shouldLogAnalytics = () => {
    if (!isProduction) return false;
    const currentUser = getCurrentUser();
    // Log analytics if there's no user or if the user is not marked as internal.
    return !currentUser || !currentUser.internal;
};

export const logPageView = () => {
    if (shouldLogAnalytics()) {
        const page = window.location.pathname + window.location.search;
        try {
            ReactGA.send({ hitType: 'pageview', page });
        } catch (error) {
            console.error('Failed to log page view to GA', error);
        }
    }
};

export const logEvent = (category, action, value = null) => {
    if (!shouldLogAnalytics()) return;
    if (!category || !action) {
        console.log('Category or action missing, not logging event');
        return;
    }
    // Construct a consistent event name and properties object.
    const eventName = `${category}_${action}`.toLowerCase().replace(/\s+/g, '_');
    const eventProperties = {
        event_category: category,
        event_label: action,
    };
    if (value !== null) {
        eventProperties.value = value;
    }
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.uuid) {
        eventProperties.user_id = currentUser.uuid;
    }
    try {
        ReactGA.event(eventName, eventProperties);
    } catch (error) {
        console.error('Failed to log event to GA', error);
    }
    try {
        amplitude.track(eventName, eventProperties);
    } catch (error) {
        console.error('Failed to log event to Amplitude', error);
    }
};