import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const useCaseQuerySuggestedQuestions = (casePresenter) => {
  const { t } = useTranslation();
  const inquiryPrompts = casePresenter.getInquiryPrompts();

  const initialSuggestedQuestions = useRef(null);

  if (initialSuggestedQuestions.current === null) {
    if (inquiryPrompts.length > 0) {
      initialSuggestedQuestions.current = inquiryPrompts;
    } else {
      const defaultQuestions = t('case_queries.suggested_questions', { returnObjects: true });
      const shuffled = [...defaultQuestions].sort(() => 0.5 - Math.random());
      const results = shuffled.slice(0, 2);
      initialSuggestedQuestions.current = [
        { long_question: results[0], short_question: results[0] },
        { long_question: results[1], short_question: results[1] },
      ];
    }
  }
  
  return initialSuggestedQuestions.current;
};
