import React from 'react';
import CaseTextModal from './CaseTextModal';
import createCase from '../../utils/case/createCase';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logEvent } from "../../analytics";

const CaseTextNew = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCreateCase = async (text) => {
    const response = await createCase({ text });
    logEvent('CaseNew', 'Text');
    if (response && response.getUUID()) {
      // f=1 signifies that this is the 1st time the case is displayed to user (which we need to know to highlight clinical insights)
      navigate(`/cases/${response.getUUID()}?f=1`);
    } else {
      throw new Error('Failed to create case');
    }
  };

  return (
    <CaseTextModal
      isVisible={isVisible}
      onClose={onClose}
      title={t('case_new.title')}
      submitButtonText={t('cases.create')}
      placeholderText={t('cases.fields.summary_placeholder')}
      onSubmit={handleCreateCase}
      dataTestId="case-text-new-modal"
    />
  );
};

export default CaseTextNew;