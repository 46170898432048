import React from 'react';
import { useTranslation } from 'react-i18next';

let handleSignIn = (origin, locale = null, signupParams = {}) => {
  const server_host = process.env.REACT_APP_SERVER_HOST;
  let googleRedirectUrl = `${server_host}/auth/google_oauth2`;
  const queryParams = [];

  if (origin && origin !== '/') {
    queryParams.push(`origin=${encodeURIComponent(origin)}`);
  }
  if (locale) {
    queryParams.push(`locale=${encodeURIComponent(locale)}`);
  }
  // Loop over signupParams and add each key-value pair as a query parameter
  Object.keys(signupParams).forEach(key => {
    const value = signupParams[key];
    if (value) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });

  // Append query parameters if any exist
  if (queryParams.length > 0) {
    googleRedirectUrl += `?${queryParams.join('&')}`;
  }
  console.log('redirecting to ', googleRedirectUrl);
  window.location.href = googleRedirectUrl;
};

// used in tests
export const setHandleSignIn = (func) => {
  handleSignIn = func;
};

const GoogleSignIn = ({ origin = '/', locale = null, signupParams = {} }) => {
  const { t } = useTranslation();

  // Google brand colors and styling
  const googleButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '44px',
    backgroundColor: '#ffffff',
    border: '1px solid #dadce0',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
    color: '#3c4043',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.25px',
    transition: 'background-color 0.218s, border-color 0.218s, box-shadow 0.218s',
    cursor: 'pointer',
    outline: 'none',
  };

  const googleButtonHoverStyle = {
    backgroundColor: '#f8f9fa',
    borderColor: '#dadce0',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
  };

  const googleLogoStyle = {
    marginRight: '12px',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  };

  const [isHovered, setIsHovered] = React.useState(false);

  // Custom Google button instead of using Ant Design Button
  return (
    <div
      style={{
        ...googleButtonStyle,
        ...(isHovered ? googleButtonHoverStyle : {})
      }}
      onClick={() => handleSignIn(origin, locale, signupParams)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="button"
      aria-label={t('sign_in.fields.continue_with_google')}
      data-testid="google-signin-button"
    >
      <span style={googleLogoStyle}>
        <svg width="20" height="20" viewBox="0 0 24 24">
          <path
            fill="#4285F4"
            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          />
          <path
            fill="#34A853"
            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          />
          <path
            fill="#FBBC05"
            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          />
          <path
            fill="#EA4335"
            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          />
        </svg>
      </span>
      {t('sign_in.fields.continue_with_google')}
    </div>
  );
};

export default GoogleSignIn;
