import React, { useState } from 'react';
import { Button, Modal, Tooltip, Typography } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import createMagicLink from '../utils/user/createMagicLink';
import {handleError} from "../utils/errorHandling";
import {MobileOutlined} from "@ant-design/icons";
import { logEvent } from '../analytics';

const { Text } = Typography;

const MagicLinkQRCode = ({ redirectTo = null, label = null, source = null, type = null }) => {
  const { t } = useTranslation();
  const [magicLink, setMagicLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const buttonType = type || 'link';
  const clickSource = source || 'Sessions';
  const linkLabel = label || t('magic_link.sessions_label');
  const modalTitle = t('magic_link.sessions_modal_title');
  const modalSubtitle = t('magic_link.sessions_modal_subtitle');

  const handleGenerateMagicLink = async () => {
    setLoading(true);
    try {
      const link = await createMagicLink();
      logEvent('MagicLink', clickSource);
      // Append redirect_to query parameter if provided
      const finalLink = redirectTo
        ? `${link}&redirect_to=${encodeURIComponent(redirectTo)}`
        : link;
      setMagicLink(finalLink);
      setModalVisible(true);
    } catch (error) {
      handleError('Failed to create magic link');
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <div data-testid='switch-to-mobile-btn' style={{ textAlign: 'center' }}>
      <Tooltip title={clickSource === 'Sessions' ? modalSubtitle : ''}>
        <Button
          icon={<MobileOutlined />}
          type={buttonType}
          onClick={handleGenerateMagicLink}
          disabled={loading}
          style={{ color: 'var(--main-color)', borderColor: 'var(--main-color)', borderRadius: '40px' }}
        >
          {linkLabel}
        </Button>
      </Tooltip>
      <Modal
        title={modalTitle}
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <div style={{textAlign: 'center'}}>
          <Text>{modalSubtitle}</Text>
          {magicLink && (
            <div style={{marginTop: '30px', marginBottom: '30px'}} data-testid="qrcode">
              <QRCodeSVG value={magicLink} size={150}/>
            </div>
          )}
          {/* inject the magic-link into the DOM for testing purposes */}
          {magicLink && (
            <p data-testid="magic-link-text" style={{ display: 'none' }}>
              {magicLink}
            </p>
          )}
        </div>
      </Modal>
    </div>
);
};

export default MagicLinkQRCode;
