import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import authRequest from '../utils/authRequest';
import { getToken } from '../utils/tokenStorage';
import { handleAuthSuccess } from "../utils/authSuccess";
import { toast } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Form, Row, Col, Typography, Divider, Card } from 'antd';
import EmailInput from '../components/forms/EmailInput';
import PasswordInput from '../components/forms/PasswordInput';
import FormSubmit from '../components/forms/FormSubmit';
import Testimonials from '../components/Testimonials';
import GoogleSignIn from "../components/GoogleSignIn";
import supportedLocales from '../utils/supportedLocales';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import './SignUp.css';

const { Title, Text, Paragraph } = Typography;

const SignUp = ({ signupParams = {}, alertComponent = null }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentTimezone = moment.tz.guess();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rerenderTrigger, setRerenderTrigger] = useState(false);

  // Determine the redirect URL only once
  const redirectUrl = location.state?.from?.pathname || '/';

  const passedLocale = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const localeParam = queryParams.get('locale');
    if (!localeParam || !supportedLocales.includes(localeParam)) {
      return null;
    }
    return localeParam;
  }, [location.search]);

  // Update i18n.language if passedLocale exists
  useEffect(() => {
    if (passedLocale && passedLocale !== i18n.language) {
      i18n.changeLanguage(passedLocale).then(() => {
        console.log('Changing language to:', passedLocale);
        // force re-render
        setRerenderTrigger(prev => !prev);
      }).catch((error) => {
        console.error('Failed to change language:', error);
      });
    }
  }, [passedLocale, i18n]);

  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate(redirectUrl, { replace: true });
    }
  }, [navigate, redirectUrl]);

  const handleSignUp = async (values) => {
    setIsSubmitting(true);
    try {
      const { email, password } = values;
      const response = await authRequest('/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: {
            email,
            password,
            locale: passedLocale,
            timezone: currentTimezone,
            ...signupParams
          },
        }),
      });

      if (response.status === 200) {
        const authHeader = response.headers.get('Authorization');
        if (authHeader) {
          const authToken = authHeader.split(' ')[1];
          handleAuthSuccess(authToken, redirectUrl);
          toast.success(t('sign_up.success'));
        } else {
          handleError(t('sign_up.errors.invalid_token'));
        }
      } else {
        handleError(response?.data?.error);
      }
    } catch (error) {
      handleError(error?.response?.data?.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleError = (err_msg) => {
    toast.error(err_msg);
    console.error('Sign up error: ' + err_msg);
  };

  // Function to open terms page based on locale
  const openTermsPage = () => {
    const termsUrl = i18n.language.includes('es') ? '/terms_es' : '/terms_en';
    window.open(termsUrl, '_blank');
  };

  const openPrivacyPage = () => {
    const privacyUrl = i18n.language.includes('es') ? '/privacy_es' : '/privacy_en';
    window.open(privacyUrl, '_blank');
  };

  return (
    <div className="signup-page">
      {alertComponent}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="signup-row">
        <Col 
          xs={{ span: 24, order: 1 }} 
          sm={{ span: 24, order: 1 }} 
          md={{ span: 12, order: 1 }} 
          className="form-column"
        >
          <div className="form-container">
            <Form form={form} onFinish={handleSignUp} style={{width: '100%'}}>
              <Title level={3} className="form-title">
                {t('sign_up.title')}
              </Title>
              <GoogleSignIn origin={redirectUrl} locale={passedLocale} signupParams={signupParams} />

              <Divider plain>{t('sign_in.fields.or')}</Divider>
              <EmailInput/>
              <PasswordInput/>
              <FormSubmit label={t('sign_up.fields.sign_up')} isSubmitting={isSubmitting}/>
              <p>
                {t('sign_up.fields.have_account')} <Link style={{width: '50%'}} to="/sign_in" data-testid="sign-in-link">{t('sign_up.fields.sign_in_here')}</Link>
              </p>
              <Text type="secondary" className="legal-text">
                {t('sign_up.fields.agree_terms')} <a href='#' style={{ color: 'var(--main-color)' }} onClick={openTermsPage} data-testid='terms-link'>{t('sign_up.fields.terms_link')}</a> {t('sign_up.fields.privacy_connector')} <a href='#' style={{ color: 'var(--main-color)' }} onClick={openPrivacyPage} data-testid='privacy-link'>{t('sign_up.fields.privacy_link')}</a>
              </Text>
            </Form>
          </div>
        </Col>
        <Col 
          xs={{ span: 24, order: 2 }} 
          sm={{ span: 24, order: 2 }} 
          md={{ span: 12, order: 2 }} 
          className="testimonial-column"
        >
          <Testimonials />
        </Col>
      </Row>

      <Card className="security-card">
        <SafetyCertificateOutlined className="security-icon" />
        <Title level={4} className="security-title">{t('social_proof.secure_compliant')}</Title>
        <Paragraph>
          {t('social_proof.hipaa_compliant')}
        </Paragraph>
      </Card>
    </div>
  );
};

export default SignUp;
