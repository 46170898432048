import React, { useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';
import toast from 'react-hot-toast';
import generatePDF, { Margin } from 'react-to-pdf';

const DownloadPDFButton = ({ pdfRef, eventCategory, disabled }) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadPDF = async () => {
    logEvent(eventCategory, 'PDF');
    setIsDownloading(true);
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 10);
    const formattedTime = now.toTimeString().slice(0, 5).replace(':', '-');
    const filename = `document_${formattedDate}_${formattedTime}.pdf`;
    const pdfOptions = {
      filename,
      page: { margin: Margin.MEDIUM },
    };

    try {
      await generatePDF(pdfRef, pdfOptions);
      toast.success(t('download_pdf.success'));
    } catch (error) {
      toast.error(t('download_pdf.error'));
    }
    setIsDownloading(false);
  };

  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={handleDownloadPDF}
      disabled={isDownloading || disabled}
    >
      {t('download_pdf.label')}
    </Button>
  );
};

export default DownloadPDFButton;
