import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Divider, Grid, Tag, Image, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { getCurrentUser } from "../utils/userStorage";
import authRequest from "../utils/authRequest";
import { handleAuthFailure } from "../utils/authFailure";
import ShareLoveButton from "./buttons/ShareLoveButton";
import SupportModal from "./SupportModal";
import InstallPWAButton from "./buttons/InstallPWAButton";
import { useInstallPrompt } from "../hooks/useInstallPrompt";
import {
  HomeOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  MessageOutlined,
  CustomerServiceOutlined,
  UserOutlined,
  LogoutOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import logo from "../assets/logo_es.png";
import logo_small from '../assets/logo_small.png';
import supportedFeature from "../utils/supportedFeature";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

function SideNavigation() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUser = getCurrentUser();
  const userEmail = currentUser?.email || '';
  const [collapsed, setCollapsed] = useState(false);
  const screens = useBreakpoint();
  const [isSupportModalVisible, setIsSupportModalVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const { isInstallable } = useInstallPrompt();

  useEffect(() => {
    setCollapsed(!screens.md);
  }, [screens.md]);

  useEffect(() => {
    document.documentElement.classList.toggle('html-dark', isDarkMode);
  }, [isDarkMode]);

  const handleSignOut = async () => {
    try {
      await authRequest('/logout', {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      await handleAuthFailure();
    }
  };

  const getSelectedKey = () => {
    if (location.pathname === '/') return '1';
    const match = menuItems.find(item =>
      item.link !== '/' && location.pathname.startsWith(item.link)
    );
    return match ? match.key : '';
  };

  // Only add the install item if it's installable
  const installPWAMenuItem = isInstallable ? { key: "6", icon: <InstallPWAButton /> } : null;

  const menuItems = [
    { key: '1', icon: <HomeOutlined />, label: t('navigation_header.home'), link: '/' },
    { key: '2', icon: <MessageOutlined />, label: t('navigation_header.queries'), link: '/queries/new' },
    { key: '3', icon: <FolderOpenOutlined />, label: t('navigation_header.cases'), link: '/cases' },
    { key: '4', icon: <FileTextOutlined />, label: t('navigation_header.sessions'), link: '/sessions' },
    { key: '5', icon: <PhoneOutlined className="rotated-icon" />, label: t('navigation_header.calls'), link: '/calls' }
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemSelectedBg:'#003f3f',
            darkItemHoverBg: '#001f1f ',
            darkSubMenuItemBg: ''
          }
        }
      }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        breakpoint="md"
        collapsedWidth={80}
        trigger={null}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <Link to="/">
            <Image
              src={ collapsed ? logo_small : logo }
              alt="Logo"
              preview={false} // Disable the preview modal for better UX in this case
              style={{
                width: collapsed ? '30px' : '120px',
                marginBottom: '16px',
              }}
            />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[getSelectedKey()]}
          items={menuItems.map((item) => ({
            key: item.key,
            icon: item.icon,
            title: collapsed ? false : item.title,
            label: <Link to={item.link}>{item.label}</Link>
          }))}
        />
        <Divider style={{ margin: '16px 0', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
        <div>
          <Menu
            theme="dark"
            mode="inline"
            selectable={false}
            items={[
              // Display Install app only if it's Chrome and it's installable
              installPWAMenuItem,
              {
                key: '7',
                icon: <ShareLoveButton />,
              },
              {
                key: '8',
                icon: <CustomerServiceOutlined />,
                label: collapsed ? null : t('support.title'),
                onClick: () => setIsSupportModalVisible(true),
              },
              {
                key: '9',
                icon: <UserOutlined />,
                label: t('settings.account'),
                children: [
                  {
                    key: '9-1',
                    label: userEmail,
                    disabled: true,
                  },
                  {
                    key: '9-2',
                    icon: <LogoutOutlined />,
                    label: t('sign_out.title'),
                    onClick: handleSignOut,
                  },
                ],
              },
            ].filter(Boolean)} // Filter out null or undefined items
          />
          <SupportModal
            visible={isSupportModalVisible}
            onClose={() => setIsSupportModalVisible(false)}
          />
        </div>
      </Sider>
    </ConfigProvider>
  );
}

export default SideNavigation;
