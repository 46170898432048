import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Typography } from 'antd';
import { BuildOutlined } from '@ant-design/icons';
import SignUp from './SignUp';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

// Organizations definition
const organizations = {
  virtualmd: {
    name: 'VirtualMD',
  },
  medicasur: {
    name: 'Médica Sur',
  },
  // Add more organizations as needed
};

const SignUpWithOrganization = () => {
  const { organizationSlug } = useParams();
  const { t } = useTranslation();

  const slug = organizationSlug?.toLowerCase();
  const org = slug && organizations[slug];
  const isValid = Boolean(org);
  const signupParams = isValid ? { organization_slug: slug } : {};

  const alertComponent = isValid ? (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '20px auto' }}>
      <Alert
        message={
          <Title level={5} style={{ marginTop: 0 }}>
            {t('sign_up_with_organization.title', { org_name: org.name })}
          </Title>
        }
        description={t('sign_up_with_organization.subtitle')}
        type="info"
        showIcon
        icon={<BuildOutlined />}
      />
    </div>
  ) : null;

  return <SignUp signupParams={signupParams} alertComponent={alertComponent} />;
};

export default SignUpWithOrganization;