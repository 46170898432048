import React, { useState } from 'react';
import {Modal, Button, Typography, List, Tag} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { isWindows, isMacOs } from 'react-device-detect';
import { useInstallPrompt } from '../../hooks/useInstallPrompt';
import Bugsnag from "@bugsnag/js";
import {logEvent} from "../../analytics";

const { Text } = Typography;

const InstallPWAButton = () => {
  const { t } = useTranslation();
  const { promptInstall } = useInstallPrompt(); // use for handling the click
  const [isModalOpen, setIsModalOpen] = useState(false);

  const instructions = [
    t('pwa.fields.instruction1'),
    t('pwa.fields.instruction2'),
  ];
  if (isMacOs) {
    instructions.push(t('pwa.fields.instruction3_mac'));
  } else if (isWindows) {
    instructions.push(t('pwa.fields.instruction3_win'));
  }

  const handleInstallClick = async () => {
    logEvent('PWA', 'OpenModal');
    try {
      const outcome = await promptInstall();
      console.log("Install outcome:", outcome);
    } catch (error) {
      console.error("Error during installation:", error);
      Bugsnag.notify(error);
    } finally {
      // Close the modal regardless of the outcome.
      setIsModalOpen(false);
    }
  };

  return (
    <div data-testid="install-pwa-component">
      <div
        onClick={() => setIsModalOpen(true)}
        data-testid="install-pwa-button"
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <DownloadOutlined />
        <span>{t('pwa.title')}</span>
        <Tag color="#108ee9" style={{ marginLeft: '8px', fontSize: '10px', padding: '2px 4px', lineHeight: '1' }}>
          {t('common.new')}
        </Tag>
      </div>
      <Modal
        title={t('pwa.title')}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={550}
      >
        <Text>{t('pwa.subtitle')}</Text>
        <List
          dataSource={instructions}
          renderItem={(item) => (
            <List.Item style={{ padding: '5px 0', border: 'none' }}>
              <Text>{item}</Text>
            </List.Item>
          )}
          style={{ marginTop: '20px' }}
        />
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleInstallClick}
            style={{ width: '100%', maxWidth: '150px' }}
          >
            {t('pwa.fields.button_label')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default InstallPWAButton;
