import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QuestionButton from './QuestionButton';
import { logEvent } from '../../analytics';

const QueryRelatedQuestions = ({ relatedQuestions, previousQueryUuid, queriesLandingPage = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!relatedQuestions || relatedQuestions.length === 0) return null;

  const handleQuestionClick = (question) => {
    const category = queriesLandingPage ? 'QueryNew' : 'QueryFollowup';
    logEvent(category, 'RelatedQuestion')
    let url = `/queries?q=${encodeURIComponent(question)}`;
    if (previousQueryUuid) {
      url += `&previous_query_uuid=${encodeURIComponent(previousQueryUuid)}`;
    }
    navigate(url);
  };

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
    }}>
      {relatedQuestions.map((question, index) => {
        const trimmed = question.trim();
        const formattedQuestion = trimmed.endsWith('?') ? trimmed : `${trimmed}?`;
        return (
          <QuestionButton
            key={index}
            text={formattedQuestion}
            onClick={() => handleQuestionClick(formattedQuestion)}
          />
        );
      })}
    </div>
  );
};

export default QueryRelatedQuestions;