import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Row, Col, Grid, Layout, Card } from 'antd';
import fetchCase from '../utils/case/fetchCase';
import CaseSummary from '../components/case/CaseSummary';
import CaseLoadingCard from '../components/case/CaseLoadingCard';
import useCaseTextEntryPolling from '../hooks/useCaseTextEntryPolling';
import { handleError } from '../utils/errorHandling';
import CaseDetailsExtended from '../components/case/CaseDetailsExtended';
import CaseDetailsInsights from '../components/case/CaseDetailsInsights';
import CaseChatFixed from '../components/case/CaseChatFixed';
import BackLink from '../components/BackLink';
import { useTranslation } from 'react-i18next';
import CaseQuerySuggestedQuestions from '../components/case/CaseQuerySuggestedQuestions';
import CaseActions from '../components/case/CaseActions';
import CaseDocuments from "../components/case/CaseDocuments";
import { useCaseInsightsHighlight } from '../hooks/useCaseInsightsHighlight';

const { Title } = Typography;
const { useBreakpoint } = Grid;
const { Content } = Layout;

const CaseDetails = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  // Use our custom hook; it returns a tuple [highlight, setHighlight]
  const [highlight, setHighlight] = useCaseInsightsHighlight();

  const [caseData, setCaseData] = useState(null);
  const [changeset, setChangeset] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const [trigger, setTrigger] = useState(0); // Trigger state to reload case details
  const [clearChangeset, setClearChangeset] = useState(false); // New state to control clearing changeset

  // Create a ref to control CaseChatFixed imperatively.
  const chatRef = useRef(null);

  const processFetchedEntry = useCallback(async (entryUuid) => {
    const fetchedEntry = await pollCaseTextEntry(entryUuid);
    // if polling timeouts - don't do anything, just return
    if (!fetchedEntry) {
      return;
    } else if (fetchedEntry.hasProblem()) {
      handleError(fetchedEntry.getProblem());
      return;
    }

    if (fetchedEntry?.getChangeset()) {
      setChangeset(fetchedEntry.getChangeset());
      setClearChangeset(false); // Ensure changeset is not cleared until used
    }

    setTrigger(prev => prev + 1); // Trigger re-fetching the case
    return fetchedEntry;
  }, []);

  const loadCaseDetails = useCallback(async () => {
    try {
      setLoading(true);
      const casePresenter = await fetchCase(uuid);
      if (casePresenter.isReadyButInvalid()) {
        // case would be invalid if gen_title is N/A, which happens mostly when transcript is blank
        throw new Error(t('cases.create_error'));
      }
      setCaseData(casePresenter);

      const latestEntry = casePresenter.getLatestTextEntry();
      // First, check if we need to clear the changeset based on the clearChangeset flag
      if (clearChangeset) {
        setChangeset(null);
        setClearChangeset(false);
      }

      // if changeset is present, we need to clear it on the next reload
      if (changeset) {
        setClearChangeset(true);
      }

      if (!latestEntry || latestEntry.isCompleted()) {
        // If there is no latest entry or the latest entry is already completed, exit early.
        return;
      }

      // If there's an entry that is not completed, poll it
      await processFetchedEntry(latestEntry.uuid);
    } catch (error) {
      handleError(error);
      navigate('/cases');
    } finally {
      setLoading(false);
    }
  }, [uuid, changeset]);

  const { processingEntry, pollCaseTextEntry } = useCaseTextEntryPolling();

  useEffect(() => {
    loadCaseDetails();
  }, [loadCaseDetails, trigger]); // Re-fetch case details when the trigger changes

  const handleEntrySubmitted = async (entryUuid) => {
    await processFetchedEntry(entryUuid);
    // After updating, clear the highlight flag (if insights section was updated, the highlighting will be turned by the CaseInsights component)
    setHighlight(false);
  };

  const handleDocumentAttached = (textEntryUuid) => {
    console.log('Document attached with text entry UUID:', textEntryUuid);
    setTrigger(prev => prev + 1); // Reload case details
  };

  if (loading || processingEntry) {
    return <CaseLoadingCard isProcessingEntry={processingEntry} />;
  }

  return (
    <Content className="page case-details-page">
      {/* Pass the ref to CaseChatFixed */}
      <CaseChatFixed
        ref={chatRef}
        caseUuid={uuid}
        caseQueries={caseData.getQueries()}
        casePresenter={caseData}
      />
      <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col flex="none">
          <BackLink to="/cases" label={t('case_details.cases')} />
        </Col>
        <Col flex="auto">
          <Title level={4} style={{ margin: 0, marginLeft: '10px' }}>
            {caseData.getTitle(t)}
          </Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Card
          style={{ marginLeft: '8px', marginRight: '8px' }}
          styles={{ body: { padding: '15px' }}}
          extra={
            <CaseActions
              caseUuid={uuid}
              onCaseUpdated={handleEntrySubmitted}
              onDocumentAttached={handleDocumentAttached}
            />
          }
        >
          <Col xs={24}>
            <CaseSummary casePresenter={caseData} changeset={changeset} />
          </Col>
        </Card>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col xs={24} md={screens.md ? 12 : 24}>
          <CaseDetailsExtended caseData={caseData} changeset={changeset} />
        </Col>
        <Col xs={24} md={screens.md ? 12 : 24}>
          {caseData.displayInsights() && (
            <CaseDetailsInsights
              caseData={caseData}
              changeset={changeset}
              highlight={highlight}
            />
          )}
          {/* When a suggested question here is clicked, open the chat and populate its input */}
          <CaseQuerySuggestedQuestions
            onQuestionClick={(question) => {
              if (chatRef.current) {
                chatRef.current.openChatWithInput(question);
              }
            }}
            casePresenter={caseData}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col xs={24}>
          <CaseDocuments
            caseData={caseData}
            caseUuid={uuid}
            onDocumentAttached={handleDocumentAttached}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '16px', marginLeft: '7px' }}>
        <BackLink to="/cases" label={t('case_details.cases')} />
      </Row>
    </Content>
  );
};

export default CaseDetails;
