import React, { useState } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import CaseTextUpdate from '../../components/case/CaseTextUpdate';
import { useTranslation } from 'react-i18next';

const UpdateCaseButton = ({ caseUuid, onCaseUpdated }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={handleOpenModal}
        data-testid="update-case-button"
      >
        {t('case_text_entry.title')}
      </Button>
      {isModalVisible && (
        <CaseTextUpdate
          isVisible={isModalVisible}
          onClose={handleCloseModal}
          caseUuid={caseUuid}
          onUpdateSuccess={onCaseUpdated}
        />
      )}
    </>
  );
};

export default UpdateCaseButton;