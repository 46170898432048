import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import fetchCaseTextEntry from '../utils/case/fetchCaseTextEntry';
import { handleError } from '../utils/errorHandling';

const useCaseTextEntryPolling = () => {
  const [processingEntry, setProcessingEntry] = useState(false);
  const { t } = useTranslation();

  const pollCaseTextEntry = useCallback(async (entryUuid) => {
    const maxAttempts = 60;
    let attempts = 0;

    setProcessingEntry(true);
    console.log('Polling started for entry:', entryUuid);

    while (attempts < maxAttempts) {
      try {
        console.log(`Polling for entry: ${entryUuid}`);
        const entry = await fetchCaseTextEntry(entryUuid);

        if (entry.isCompleted()) {
          console.log('Entry completed:', entryUuid);
          setProcessingEntry(false);
          return entry; // Return the fetched entry when completed
        }

        attempts++;

        // Wait for 2 seconds before the next attempt
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } catch (error) {
        console.error('Error during polling:', error);
        setProcessingEntry(false);
        handleError(t('case_text_entry.text_entry_processing_error'));
        return null; // Return null if there was an error
      }
    }

    // If the loop completes without returning, it means polling timed out
    console.error('Polling timed out for entry:', entryUuid);
    setProcessingEntry(false);
    handleError(t('case_text_entry.polling_timeout_error'));
    return null;
  }, [t]);

  return { processingEntry, pollCaseTextEntry };
};

export default useCaseTextEntryPolling;
