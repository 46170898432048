import React, { useState } from 'react';
import { AutoComplete, Input, Spin, Grid } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import searchQueries from '../../utils/query/searchQueries';
import { logEvent } from '../../analytics';

const { useBreakpoint } = Grid;

const QuerySearchAutocomplete = ({ onSelectQuery }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (!value || value.length < 3) {
      setOptions([]);
      return;
    }
    setLoading(true);
    try {
      const results = await searchQueries(value);
      const newOptions = results.map(query => ({
        key: query.getUUID(),
        value: query.question,
        label: query.question,
        query,
      }));
      setOptions(newOptions);
    } catch (error) {
      console.error('Search error:', error);
      setOptions([]);
    }
    setLoading(false);
  };

  const onSelect = (value, option) => {
    if (option && option.query && option.query.getUUID) {
      logEvent('Queries', 'Search');
      const queryUuid = option.query.getUUID();
      navigate(`/queries/${queryUuid}`);
    }
    setSearchValue('');
    if (onSelectQuery) {
      onSelectQuery(option.query);
    }
  };

  return (
    <AutoComplete
      style={{ width: '100%' }}
      popupMatchSelectWidth={ !screens.md }  // do not force dropdown to match the input width on desktop
      dropdownStyle={{ width: screens.md ? '700px' : '300px' }}  // set dropdown width for desktop/mobile
      options={options}
      onSearch={handleSearch}
      onSelect={onSelect}
      value={searchValue}
      onChange={setSearchValue}
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={false}
    >
      <Input
        prefix={<SearchOutlined />}
        placeholder={t('query_search.placeholder')}
        style={{ fontSize: '16px' }}
      />
    </AutoComplete>
  );
};

export default QuerySearchAutocomplete;