import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';
import QueryForm from '../query/QueryForm';
import QuestionButton from '../query/QuestionButton';
import QueryRelatedQuestions from '../query/QueryRelatedQuestions';

const { Title } = Typography;

const QueryFormCard = ({
                         initialQuestion,
                         onSubmit,
                         handleExampleQuery,
                         hasRecentQueries,       // Optional prop: if true, show related questions instead of example queries
                         relatedQuestions,       // Array of related questions
                         previousQueryUuid,      // UUID from a recent query to use for query continuation
                       }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Title level={4} style={{ marginTop: '0', marginBottom: '15px' }}>
        <MessageOutlined style={{ marginRight: '8px' }} />
        {t('query_new.title')}
      </Title>
      <QueryForm initialQuestion={initialQuestion} onSubmit={onSubmit} />

      {hasRecentQueries ? (
        <>
          <Title level={5}>{t('query_new.fields.continue_exploring_title')}</Title>
          <div style={{ marginTop: '20px' }}>
            <QueryRelatedQuestions
              relatedQuestions={relatedQuestions}
              previousQueryUuid={previousQueryUuid}
              queriesLandingPage={true}
            />
          </div>
        </>
      ) : (
        <>
          <Title level={5}>{t('query_new.fields.examples_title')}</Title>
          <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <QuestionButton
                text={t('query_new.fields.example_q1')}
                onClick={() => handleExampleQuery(t('query_new.fields.example_q1'))}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <QuestionButton
                text={t('query_new.fields.example_q2')}
                onClick={() => handleExampleQuery(t('query_new.fields.example_q2'))}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <QuestionButton
                text={t('query_new.fields.example_q3')}
                onClick={() => handleExampleQuery(t('query_new.fields.example_q3'))}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <QuestionButton
                text={t('query_new.fields.example_q4')}
                onClick={() => handleExampleQuery(t('query_new.fields.example_q4'))}
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default QueryFormCard;
