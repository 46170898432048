import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Space, Layout, Grid } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import fetchCasesList from '../utils/case/fetchCasesList';
import CaseEmptyState from '../components/case/CaseEmptyState';
import NewCaseButton from '../components/case/NewCaseButton';
import CaseTextNew from "../components/case/CaseTextNew";

const { useBreakpoint } = Grid;
const { Content } = Layout;

const Cases = () => {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const feedbackSource = 'cases';

  useEffect(() => {
    fetchCases(pagination.current);
  }, []);

  const fetchCases = async (page) => {
    setLoading(true);
    const { cases, pagination: paginationData } = await fetchCasesList(page);
    setCases(cases);
    setPagination(prev => ({
      ...prev,
      current: paginationData.current_page,
      total: paginationData.total_entries,
    }));
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    fetchCases(pagination.current);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: t('cases.title'),
      dataIndex: 'gen_title',
      key: 'gen_title',
      render: (text, record) => <Link to={`/cases/${record.uuid}`}>{record.getTitle(t)}</Link>,
    },
    {
      title: '',
      dataIndex: 'gen_summary',
      key: 'gen_summary',
      responsive: ['sm'], // shown only on small screens and above
      render: (text, record) => record.getTruncatedSummary(),
    },
    {
      title: t('cases.fields.last_updated'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      responsive: ['md'], // shown only on small screens and above
      render: (text, record) => record.getUpdatedAt(),
    },
  ];

  if (loading) {
    return (
      <Content className="page">
        <Skeleton active />
        <Space />
        <Skeleton active />
        <Space />
        <Skeleton active />
      </Content>
    );
  }

  return (
    <Content className="page cases-page">
      {cases?.length > 0 ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '16px' }}>
            <NewCaseButton onCaseCreated={fetchCases} />
          </div>
          <Table
            columns={columns}
            dataSource={cases}
            rowKey={(record) => record.getUUID()}
            pagination={pagination.total > pagination.pageSize ? pagination : false}
            loading={loading}
            onChange={handleTableChange}
          />
        </>
      ) : (
        <CaseEmptyState showModal={showModal} />
      )}
      <CaseTextNew isVisible={isModalVisible} onClose={handleModalClose} />
    </Content>
  );
};

export default Cases;
