import React from 'react';
import CaseDetailsCollapse from './CaseDetailsCollapse';
import { useTranslation } from 'react-i18next';
import CaseChangesPresenter from '../../presenters/CaseChangesPresenter';

const insightsStyle = {
  marginBottom: '20px',
};

const CaseDetailsInsights = ({ caseData, changeset, highlight = false }) => {
  const { t } = useTranslation();

  const sections = [
    {
      key: 'ai_insights',
      title: t('case_details.ai_findings'),
      fields: [
        { key: 'considerations' },
        { key: 'inconsistencies' },
      ],
    },
  ];

  // Create a changes presenter and check if any insight field was updated.
  const changesPresenter = new CaseChangesPresenter(caseData, changeset);
  const isUpdated = sections[0].fields.some(field =>
    changesPresenter.isUpdated(field.key)
  );
  const insightsHighlight = highlight || isUpdated;

  return (
    <div style={insightsStyle}>
      <CaseDetailsCollapse
        caseData={caseData}
        changeset={changeset}
        sections={sections}
        defaultExpanded={true}
        hideEmptySubsection={true}
        highlight={insightsHighlight}
      />
    </div>
  );
};

export default CaseDetailsInsights;