import React from 'react';
import ReactMarkdown from 'react-markdown';

const AnswerMarkdown = ({ children, isCompleted = false }) => {
  if (!children) return null;

  // Function to process citations only after completion
  const processCitations = (text) => {
    // Example regex that matches [1], [2], [3], etc.
    return text.replace(/(\[(\d+)\])+/g, (match) => {
      const citations = match.match(/\[(\d+)\]/g);
      return citations
        .map((citation) => {
          const number = citation.slice(1, -1);
          return `[${number}](#citation-${number})`;
        })
        .join('');
    });
  };

  // Two-pass logic:
  //  - If incomplete, keep the text as-is (no citation conversion).
  //  - If complete, convert the bracketed refs to links.
  const displayText = isCompleted
    ? processCitations(children)
    : children;

  return (
    <ReactMarkdown
      components={{
        a: ({ node, ...props }) => {
          const href = props.href || '';
          if (href.startsWith('#citation-')) {
            // Internal link to a citation
            const citationNumber = href.replace('#citation-', '');
            return (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  const citationElement = document.getElementById(`citation-${citationNumber}`);
                  if (citationElement) {
                    citationElement.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                [{props.children}]
              </a>
            );
          } else {
            // External link
            return (
              <a
                {...props}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'underline',
                  color: '#007BFF',
                }}
              >
                {props.children}
              </a>
            );
          }
        },
      }}
    >
      {displayText}
    </ReactMarkdown>
  );
};

export default AnswerMarkdown;