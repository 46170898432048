import React, { useState, useEffect, useMemo } from 'react';
import { Skeleton, Space, Layout, Grid, Table } from "antd";
import { useTranslation } from 'react-i18next';
import fetchSessionsList from '../utils/session/fetchSessionsList';
import SessionEmptyState from "../components/session/SessionEmptyState";
import SessionRecorder from "../components/session/SessionRecorder";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MagicLinkQRCode from "../components/MagicLinkQRCode";

const { useBreakpoint } = Grid;
const { Content } = Layout;

function Sessions() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSessions(pagination.current);
  }, [location.search]);

  const fetchSessions = async (page) => {
    setLoading(true);
    const { sessions, pagination: paginationData } = await fetchSessionsList(page);
    setSessions(sessions);
    setPagination(prev => ({
      ...prev,
      current: paginationData.current_page,
      total: paginationData.total_entries,
    }));
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    fetchSessions(pagination.current);
  };

  const sessionTitle = (record) => {
    if (record.isErrored()) {
      return t('sessions.fields.no_note');
    }

    return (
      <Link to={sessionLink(record)}>{record.getTitle()}</Link>
    )
  }

  const sessionLink = (session) => {
    if (session.isReady()) {
      return `/sessions/${session.getUUID()}`;
    } else {
      return `/sessions/${session.getUUID()}?generating=1`;
    }
  };

  const sessionCompletedAt = (session) => {
    const completedAt = session.getCompletedAt();
    const startedAt = session.getStartedAt();

    return completedAt || startedAt;
  }

  // Wrap columns definition in useMemo for stable reference
  const columns = useMemo(() => [
    {
      title: t('sessions.fields.title'),
      dataIndex: 'gen_title',
      key: 'gen_title',
      render: (text, record) => {
        if (record.isErrored()) {
          return t('sessions.fields.no_note');
        }
        return (
          <Link to={record.isReady()
            ? `/sessions/${record.getUUID()}`
            : `/sessions/${record.getUUID()}?generating=1`}>
            {record.getTitle()}
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'summary',
      key: 'summary',
      responsive: ['sm'], // only show on screens 'sm' and above
      render: (text, record) => record.getExcerpt(t),
    },
    {
      title: '',
      dataIndex: 'updated_at',
      key: 'updated_at',
      responsive: ['md'], // only show on screens 'md' and above
      render: (text, record) => {
        const completedAt = record.getCompletedAt();
        const startedAt = record.getStartedAt();
        return completedAt || startedAt;
      },
    },
  ], [t]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const pollingFailed = query.get('polling_finished');
    if (pollingFailed) {
      // Reset the URL without the query param to allow future navigations with the same param
      navigate('/sessions', { replace: true });
    }
  }, [location.search, navigate]);

  if (loading) {
    return (
      <Content className="page sessions-page">
        <Skeleton active />
        <Space />
        <Skeleton active />
        <Space />
        <Skeleton active />
      </Content>
    );
  }

  if (sessions?.length > 0) {
    return (
      <Content className="page sessions-page">
        <div className="session-recorder-top">
          <SessionRecorder showDemo={false} />
        </div>
        {screens.md && (
          <div style={{float: 'right', marginBottom: '20px', marginRight: '20px'}}>
            <MagicLinkQRCode redirectTo="/sessions" linkLabel={t('magic_link.sessions_label')} modalTitle={t('magic_link.sessions_modal_title')}/>
          </div>
        )}
        <Table
          columns={columns}
          dataSource={sessions}
          rowKey={(record) => record.getUUID()}
          pagination={pagination.total > pagination.pageSize ? pagination : false}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
    );
  } else {
    return (
      <div className="page sessions-page" data-testid="sessions-empty-state">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <SessionEmptyState />
        </Space>
      </div>
    );
  }
}

export default Sessions;
