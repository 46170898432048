import React from 'react';
import { Avatar, Card, Typography } from 'antd';
import './Testimonial.css';

const { Text, Paragraph } = Typography;

const Testimonial = ({ name, role, image, intro, quote }) => {
  return (
    <Card 
      bordered={false}
      className="testimonial-card"
    >
      <div className="testimonial-header">
        <Avatar 
          src={image} 
          size={80}
          className="testimonial-avatar"
        />
        <div className="testimonial-author">
          <Text strong className="testimonial-name">{name}</Text>
          <Text type="secondary" className="testimonial-role">{role}</Text>
        </div>
      </div>
      <div className="testimonial-quote-container">
        <Paragraph className="testimonial-intro">
          "{intro}"
        </Paragraph>
        <Paragraph className="testimonial-quote">
          {quote}
        </Paragraph>
      </div>
    </Card>
  );
};

export default Testimonial;
