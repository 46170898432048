import React from 'react';
import { Layout } from 'antd';
import logo_es from '../../assets/logo_es.png';
import logo_en from '../../assets/logo_en.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PublicLayout = ({ children }) => {
  const { Content, Header, Footer } = Layout;
  const { i18n } = useTranslation();
  const logo = i18n.language.includes('es') ? logo_es : logo_en

  return (
    <Layout className='layout'>
      <Header className='header'>
        <Link to="/" style={{display: 'flex', alignItems: 'left'}}>
          <img src={logo} alt="Itaca Logo" style={{maxHeight: '40px', marginTop: '20px'}}/>
        </Link>
      </Header>
      <Content>
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
      </Footer>
    </Layout>
  );
}

export default PublicLayout;
