import authRequest from '../authRequest';
import QueryPresenter from '../../presenters/QueryPresenter';

const searchQueries = async (term) => {
  try {
    const response = await authRequest(`/queries/search?term=${encodeURIComponent(term)}`, { method: 'GET' });
    if (response.status === 200) {
      // We expect the response to be structured like { queries: [ ... ] }
      const queries = response.data.queries.map(queryData => new QueryPresenter(queryData));
      return queries;
    } else {
      console.error("Failed to search queries:", response.statusText);
      return [];
    }
  } catch (error) {
    console.error("Failed to search queries:", error.message);
    return [];
  }
};

export default searchQueries;