import { useEffect } from 'react';
import { getToken } from '../utils/tokenStorage';

export const useRetryUpload = (isOnline, interval = 60000) => {
    useEffect(() => {
        if (!isOnline) {
            console.log('Retry upload aborted: Offline');
            return;
        }

        const token = getToken();
        if (!token) {
            console.log('Retry upload aborted: No auth token');
            return;
        }

        let worker; // store reference to the worker

        async function loadWorker() {
            const { default: RetryUploadWorker } = await import(
              'worker-loader!../workers/retryUploadWorker.js'
              );

            // Initialize the Web Worker
            worker = new RetryUploadWorker();
            // Post a message to the worker to start the retry process with the token and interval
            worker.postMessage({ type: 'INIT', data: { token, interval } });

            // Log messages from the worker
            worker.onmessage = (event) => {
                console.log('Message from worker:', event.data);
            };
        }
        loadWorker();

        // Clean up the worker on component unmount
        return () => {
            if (worker) {
                worker.terminate();
                console.log('Worker terminated');
            }
        };
    }, [isOnline, interval]);
};