import { useState, useEffect } from 'react';
import { logEvent } from '../analytics';

// Module-level variable to persist the deferred prompt across re-mounts.
let globalDeferredPrompt = null;

export function useInstallPrompt() {
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      // Prevent the default mini-infobar from appearing.
      event.preventDefault();
      console.log("useInstallPrompt: beforeinstallprompt event captured", event);
      globalDeferredPrompt = event;
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // Listen for the appinstalled event so we can update the state immediately.
  useEffect(() => {
    const handleAppInstalled = () => {
      console.log("useInstallPrompt: appinstalled event fired");
      globalDeferredPrompt = null;
      setIsInstallable(false);
    };

    window.addEventListener('appinstalled', handleAppInstalled);
    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const promptInstall = async () => {
    console.log("useInstallPrompt: promptInstall called; deferredPrompt:", globalDeferredPrompt);
    if (!globalDeferredPrompt) {
      console.warn("useInstallPrompt: no deferred prompt available");
      return;
    }
    logEvent('PWA', 'Install');
    globalDeferredPrompt.prompt();
    const { outcome } = await globalDeferredPrompt.userChoice;
    console.log("useInstallPrompt: user choice outcome:", outcome);
    // Clear the global prompt after the user has responded.
    globalDeferredPrompt = null;
    setIsInstallable(false);
    return outcome;
  };

  return { isInstallable, promptInstall };
}
