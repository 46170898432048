import moment from 'moment-timezone';

class DocumentPresenter {
  constructor(documentData) {
    this.uuid = documentData.uuid;
    this.genDocType = documentData.gen_doc_type;
    this.genDocRelevantDate = documentData.gen_doc_relevant_date;
    this.url = documentData.url;
    this.fileName = documentData.file_name;
    this.problem = documentData.problem;
  }

  getUUID() {
    return this.uuid;
  }

  getUrl() {
    return this.url;
  }

  getDocName(t) {
    if (this.hasProblem()) {
      return `${this.fileName} (${this.getProblem(t)})`;
    } else {
      const docTypeLabel = t(`case_documents.types.${this.genDocType}`)
      return `${this.fileName} (${docTypeLabel}, ${this.getRelevantDate()})`
    }
  }

  // Example helper: return a formatted date if needed
  getRelevantDate(format = 'D MMM YYYY') {
    if (!this.genDocRelevantDate) return 'N/A';
    // Assuming moment is available
    return moment.utc(this.genDocRelevantDate).format(format);
  }

  getProblem(t) {
    if (this.problem) {
      return this.problem;
    } else if (this.genDocType === null) {
      return t('case_documents.errors.invalid_file')
    }
  }

  hasProblem() {
    return !!this.problem || (this.genDocType === null);
  }
}

export default DocumentPresenter;