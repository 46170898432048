import React, { useState } from 'react';
import {Upload, Button, Modal, Spin, Typography, Grid} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import attachDocumentToCase from '../../utils/case/attachDocumentToCase';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const UploadDocumentButton = ({ caseUuid, onDocumentAttached }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [uploading, setUploading] = useState(false);

  const handleDocumentUpload = async (file) => {
    try {
      const response = await attachDocumentToCase(caseUuid, file);
      logEvent('CaseDocuments', 'ButtonUpload');
      if (onDocumentAttached) {
        onDocumentAttached(response.uuid);
      }
    } catch (error) {
      throw error;
    }
  };

  const uploadProps = {
    name: 'case[document]',
    accept: '.pdf,.docx,.xlsx',
    customRequest: ({ file, onSuccess, onError }) => {
      setUploading(true);
      handleDocumentUpload(file)
        .then(() => {
          onSuccess('ok');
          setUploading(false);
        })
        .catch((error) => {
          onError(error);
          setUploading(false);
        });
    },
    showUploadList: false,
  };

  return (
    <>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>
          {screens.sm ? t('case_actions.fields.upload_document') : t('case_actions.fields.upload_document_mobile') }
        </Button>
      </Upload>
      <Modal open={uploading} footer={null} closable={false} centered>
        <div data-testid="uploading-modal" style={{ textAlign: 'center' }}>
          <Text type="secondary">{t('case_documents.fields.uploading')}</Text>
          <Spin />
        </div>
      </Modal>
    </>
  );
};

export default UploadDocumentButton;