import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useCaseInsightsHighlight = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // Initialize highlight based on the presence of the "f" query param.
  const [highlight, setHighlight] = useState(() => searchParams.get('f') === '1');

  // Remove the "f" query parameter from the URL once read.
  useEffect(() => {
    if (searchParams.get('f') === '1') {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('f');
      navigate({ search: newSearchParams.toString() }, { replace: true });
    }
  }, [navigate, searchParams]);

  return [highlight, setHighlight];
};