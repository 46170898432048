import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import packageJson from '../package.json';

let ErrorBoundary = ({ children }) => <>{children}</>;

// configure BugSnag to register errors only on production
if (process.env.NODE_ENV === 'production') {
  const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
  const appVersion = packageJson.version;
  console.log(`Bugsnag: app version ${appVersion}`);
  // Initialize Bugsnag
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    appVersion,
    plugins: [new BugsnagPluginReact(React)]
  });

  // Initialize Bugsnag Performance Monitoring
  BugsnagPerformance.start({
    apiKey: bugsnagApiKey,
    appVersion
  });

  // Create an ErrorBoundary component with Bugsnag
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

export { Bugsnag, ErrorBoundary };
