import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';

const GetStartedButton = ({primary = true}) => {
  const { t } = useTranslation();

  const handleGetStartedClick = () => {
    logEvent('SharedQuery', 'GetStarted');
    window.open('https://itaca.ai', '_blank');
  };

  return (
    <Button
      type={primary ? "primary" : "default"}
      shape="round"
      size="large"
      onClick={handleGetStartedClick}
      style={ primary ? {} : {
        borderColor: 'var(--main-color)',
        color: 'var(--main-color)'
      }}
    >
      {t('public_queries.get_started_label')}
    </Button>
  );
};

export default GetStartedButton;
