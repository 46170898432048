import React from 'react';
import { Input, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Text } = Typography;

const containerStyle = {
  position: 'relative',
};

const textAreaStyle = {
  padding: '10px',
  fontSize: '16px',
  width: '100%',
  resize: 'vertical',
  minHeight: '80px',
  background: 'whitesmoke',
};

const buttonStyle = {
  position: 'absolute',
  bottom: '10px',
  right: '10px',
};

const hintTextStyle = {
  display: 'block',
  fontSize: '12px',
  marginTop: '8px',
  marginLeft: '3px',
  textAlign: 'right',
};

const CaseQueryInputArea = React.memo(({ query, setQuery, handleSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div style={{ padding: '0px 8px' }}>
      <div style={containerStyle}>
        <TextArea
          rows={3}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('case_queries.input_placeholder')}
          style={textAreaStyle}
          onKeyDown={handleKeyDown}
          disabled={isSubmitting}
        />
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          onClick={handleSubmit}
          disabled={!query.trim() || isSubmitting}
          style={buttonStyle}
        />
      </div>
    </div>
  );
});

export default CaseQueryInputArea;