import React, { useState } from 'react';
import { Modal, Form, Input, Button, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../utils/errorHandling';

const { TextArea } = Input;
const { Title } = Typography;

const modalStyle = {
  marginTop: '5px',
  marginBottom: '30px',
};

const textAreaStyle = {
  padding: '10px',
  backgroundColor: 'whitesmoke',
  fontSize: '16px' // Prevent mobile zoom
};

const buttonContainerStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const CaseTextModal = ({
                         isVisible,
                         onClose,
                         title, // Modal title
                         submitButtonText, // Button text
                         placeholderText, // Placeholder for text area
                         onSubmit,
                         minLength = 30,
                         dataTestId= "case-text-new-modal",
                       }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validateSummary = (_, value) => {
    if (!value || value.trim().length < minLength) {
      return Promise.reject(new Error(t('case_new.fields.entry_min_length')));
    }
    return Promise.resolve();
  };

  const handleFinish = async (values) => {
    const trimmedText = values.summary.trim();
    if (!trimmedText) return;

    try {
      setLoading(true);
      await onSubmit(trimmedText); // Call the provided submission handler
      form.resetFields(); // Reset form on success
      onClose(); // Close modal
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Title level={5} style={modalStyle}>
          {title}
        </Title>
      }
      open={isVisible}
      onCancel={onClose}
      footer={null}
      closable
      maskClosable={false}
      width={700}
      data-testid={dataTestId}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="summary"
            rules={[{ validator: validateSummary, validateTrigger: 'onFinish' }]}
          >
            <TextArea
              rows={6}
              placeholder={placeholderText}
              style={textAreaStyle}
              disabled={loading}
            />
          </Form.Item>
          <Space style={buttonContainerStyle}>
            <Button type="primary" size="large" htmlType="submit" loading={loading}>
              {submitButtonText}
            </Button>
          </Space>
        </Form>
      </Space>
    </Modal>
  );
};

export default CaseTextModal;