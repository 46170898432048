import React, { useState, useEffect } from 'react';
import { Row, Col, Layout } from 'antd';
import RecentQueries from '../components/query/RecentQueries';
import QueryFormCard from '../components/query/QueryFormCard';
import QuerySearchAutocomplete from '../components/query/QuerySearchAutocomplete';
import fetchQueriesList from '../utils/query/fetchQueriesList';
import { logEvent } from '../analytics';

const { Content } = Layout;

const QueryNew = () => {
  const [exampleQuery, setExampleQuery] = useState('');
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [hasRecentQueries, setHasRecentQueries] = useState(false);
  const [relatedQuestions, setRelatedQuestions] = useState([]);
  const [previousQueryUuid, setPreviousQueryUuid] = useState('');

  const handleReloadRecentQueries = () => {
    setReloadTrigger((prev) => prev + 1);
  };

  const handleExampleQuery = (query) => {
    // Use the question text from the selected query suggestion as an example
    setExampleQuery(query.question);
    logEvent('QueryNew', 'ExampleQuestion');
  };

  useEffect(() => {
    const checkRecentQueries = async () => {
      const { queries } = await fetchQueriesList(1);
      const hasQueries = queries.length > 0;
      setHasRecentQueries(hasQueries);
      if (hasQueries) {
        // Get the last 3 queries (assuming queries are sorted newest first)
        const recentQueries = queries.slice(0, 3);
        const selectedQuestions = [];

        // From Query1: take up to two questions (if available)
        const q1 = recentQueries[0].getRelatedQuestions ? recentQueries[0].getRelatedQuestions() : [];
        if (q1.length > 0) {
          selectedQuestions.push(q1[0]);
          if (selectedQuestions.length < 4 && q1.length > 1) {
            selectedQuestions.push(q1[1]);
          }
        }

        // From Query2: take the first question (if available)
        if (recentQueries[1] && selectedQuestions.length < 4) {
          const q2 = recentQueries[1].getRelatedQuestions ? recentQueries[1].getRelatedQuestions() : [];
          if (q2.length > 0) {
            selectedQuestions.push(q2[0]);
          }
        }

        // From Query3: take the first question (if available)
        if (recentQueries[2] && selectedQuestions.length < 4) {
          const q3 = recentQueries[2].getRelatedQuestions ? recentQueries[2].getRelatedQuestions() : [];
          if (q3.length > 0) {
            selectedQuestions.push(q3[0]);
          }
        }

        setRelatedQuestions(selectedQuestions);
        setPreviousQueryUuid(
          recentQueries[0].getUUID ? recentQueries[0].getUUID() : recentQueries[0].uuid
        );
      }
    };

    checkRecentQueries();
  }, [reloadTrigger]);

  return (
    <Content className="page query-new-page">
      {/* Integrate the search component above the query form */}
      {hasRecentQueries ? (
        <Row gutter={[24, 24]}>
          <Col xs={24} md={8} lg={8}>
            <QuerySearchAutocomplete onSelectQuery={handleExampleQuery} />
            <RecentQueries reloadTrigger={reloadTrigger} onNewQueryPage={true} />
          </Col>
          <Col xs={24} md={16} lg={16} style={{ paddingLeft: 4, paddingRight: 10 }}>
            <QueryFormCard
              initialQuestion={exampleQuery}
              onSubmit={handleReloadRecentQueries}
              handleExampleQuery={handleExampleQuery}
              hasRecentQueries={true}
              relatedQuestions={relatedQuestions}
              previousQueryUuid={previousQueryUuid}
            />
          </Col>
        </Row>
      ) : (
        <QueryFormCard
          initialQuestion={exampleQuery}
          onSubmit={handleReloadRecentQueries}
          handleExampleQuery={handleExampleQuery}
        />
      )}
    </Content>
  );
};

export default QueryNew;