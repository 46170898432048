import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getDeviceInfo } from "../../utils/getDeviceInfo";
import ReactMarkdown from 'react-markdown';
import authRequest from '../../utils/authRequest';
import { logEvent } from '../../analytics';
import { handleError } from "../../utils/errorHandling";
import { Divider } from 'antd';

const Demo = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const showModal = () => {
        logEvent('CaseDemo', 'Open');
        setIsModalOpen(true);
    };

    function displayErrorModal(title, errMsg) {
        Modal.error({
            title: title,
            content: errMsg,
        });
        console.error(title, errMsg);
    }

    const handleGenerateCase = async () => {
        logEvent('CaseDemo', 'Submit');
        const deviceInfo = getDeviceInfo();
        const formData = new FormData();
        formData.append('device_info', JSON.stringify(deviceInfo));

        try {
            const response = await authRequest('/cases/create_demo', {
                method: 'POST',
                body: formData
            });
            if (parseInt(response.status) !== 200) {
                displayErrorModal('Error', t('common.errors.general'));
            } else {
                const caseUuid = response.data.uuid;
                console.log('Demo case created:', caseUuid);
                navigate(`/cases/${caseUuid}`);
            }
            setIsModalOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Divider plain>{t('sign_in.fields.or')}</Divider>
            <Link to='#' onClick={showModal} data-testid='case-demo-link' style={{ textDecoration: 'underline', fontSize: 'medium' }}>
                <BulbOutlined className='iconSpacing' />
                <span>{t('case_demo.fields.try')}</span>
            </Link>
            <Modal
                title={t('case_demo.title')}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                width={600}
                data-testid='case-demo-modal'
            >
                <div
                    style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        padding: '4px 11px',
                        maxHeight: '400px',
                        fontSize: '16px',
                        overflow: 'auto',
                        backgroundColor: '#f5f5f5'
                    }}
                >
                    <ReactMarkdown>
                        {t('case_demo.fields.transcript')}
                    </ReactMarkdown>
                </div>
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <Button key="generateCase" type="primary" size='large' onClick={handleGenerateCase} data-testid='case-demo-generate'>
                        {t('case_demo.fields.submit')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Demo; 