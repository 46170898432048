import React, { useState, useEffect } from 'react';
import { Alert, Grid } from 'antd';

const { useBreakpoint } = Grid;

const BannerAlert = ({ alertKey, message, type = 'info', style = {} }) => {
  const [visible, setVisible] = useState(false);
  const screens = useBreakpoint();

  useEffect(() => {
    const dismissedAlerts = JSON.parse(localStorage.getItem('dismissedAlerts') || '{}');
    setVisible(!dismissedAlerts[alertKey]);
  }, [alertKey]);

  const handleClose = () => {
    const dismissedAlerts = JSON.parse(localStorage.getItem('dismissedAlerts') || '{}');
    dismissedAlerts[alertKey] = true;
    localStorage.setItem('dismissedAlerts', JSON.stringify(dismissedAlerts));
    setVisible(false);
  };

  if (!visible) return null;

  const responsiveStyle = {
    ...style,
    background: '#e6f7ff',
    borderColor: '#91d5ff',
    marginBottom: '12px',
    ...(screens.xs ? {
      padding: '8px',
      '& .ant-alert-message': {
        fontSize: '13px',
      },
      '& .ant-space': {
        gap: '8px !important',
      },
      '& .ant-btn': {
        fontSize: '12px',
        padding: '2px 8px',
        height: 'auto',
      },
      '& .anticon': {
        fontSize: '14px',
      }
    } : {})
  };

  return (
    <Alert
      banner
      type={type}
      message={message}
      closable
      showIcon={false}
      onClose={handleClose}
      style={responsiveStyle}
    />
  );
};

export default BannerAlert; 