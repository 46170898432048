import React, { useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import createQuery from '../../utils/query/createQuery';
import { handleError } from '../../utils/errorHandling';

const QueryFromUrl = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const didSubmit = useRef(false);

  useEffect(() => {
    // Prevent double submission
    if (didSubmit.current) return;
    didSubmit.current = true;

    const question = searchParams.get('q');
    const previousQueryUuid = searchParams.get('previous_query_uuid');

    if (!question) {
      navigate('/queries/new');
      return;
    }

    const submitQuery = async () => {
      try {
        const newQuery = await createQuery(question, previousQueryUuid, true);
        navigate(`/queries/${newQuery.uuid}`);
      } catch (error) {
        handleError('Failed to create query from URL');
      }
    };

    submitQuery();
  }, [searchParams, navigate]);

  return <div>Loading query...</div>;
};

export default QueryFromUrl;
