import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Card, Button, Grid, Avatar, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExpandOutlined } from '@ant-design/icons';
import CaseQueryFeed from './CaseQueryFeed';
import CaseQueryInputArea from './CaseQueryInputArea';
import CaseQuerySuggestedQuestions from './CaseQuerySuggestedQuestions';
import useCaseQuerySubmission from '../../hooks/useCaseQuerySubmission';
import ExpandableContainer from '../ExpandableContainer';
import { logEvent } from '../../analytics';

const titleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const queriesTitleStyle = {
  display: 'flex',
  alignItems: 'center',
};

const { useBreakpoint } = Grid;

const CaseQueries = forwardRef(({
  caseUuid,
  caseQueries,
  embedded = false,
  chatInput,      // externally controlled input value (if provided)
  setChatInput,   // externally controlled setter (if provided)
  casePresenter
}, ref) => {
  const { t } = useTranslation();
  const [internalQuery, setInternalQuery] = useState('');
  const query = typeof chatInput !== 'undefined' ? chatInput : internalQuery;
  const setQuery = typeof setChatInput !== 'undefined' ? setChatInput : setInternalQuery;

  const { queryFeed, isSubmitting, submitQuery } = useCaseQuerySubmission(caseUuid, caseQueries);
  const [isExpanded, setIsExpanded] = useState(false);
  const screens = useBreakpoint();
  const feedRef = useRef(null);

  useEffect(() => {
    if (feedRef.current) {
      feedRef.current.scrollTop = feedRef.current.scrollHeight;
    }
  }, [queryFeed]);

  // Expose scrollToBottom() so the parent can trigger scrolling.
  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      if (feedRef.current) {
        feedRef.current.scrollTop = feedRef.current.scrollHeight;
      }
    }
  }));

  const handleSubmit = () => {
    if (query.trim()) {
      const currentQuery = query;
      setQuery('');
      submitQuery(currentQuery);
    }
  };

  const handleMainViewQuestionClick = (question) => {
    setChatInput && setChatInput(question);
    logEvent('CaseSuggestedQuestionsMainView', 'Click')
  };

  const handleChatBoxQuestionClick = (question) => {
    setChatInput && setChatInput(question);
    logEvent('CaseSuggestedQuestionsChatBox', 'Click')
  };

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
  };

  const renderEmptyState = () => (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Empty
        image="https://img.icons8.com/plasticine/100/faq.png"
        description={t('case_queries.empty_description')}
        style={{
          fontWeight: '250'
        }}
      />
    </div>
  );

  if (embedded) {
    return (
      <div
        style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 16 }}
        data-testid="case-queries"
      >
        <div
          style={{ flex: 1, overflowY: 'auto' }}
          ref={feedRef}
          data-testid="case-query-feed"
        >
          {queryFeed && queryFeed.length > 0 ? (
            <CaseQueryFeed queryFeed={queryFeed} />
          ) : (
            renderEmptyState()
          )}
        </div>
        <CaseQueryInputArea
          query={query}
          setQuery={setQuery}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
        <CaseQuerySuggestedQuestions
          onQuestionClick={handleChatBoxQuestionClick}
          maxSuggestions={2}
          casePresenter={casePresenter}
          inChat={true}
        />
      </div>
    );
  }

  return (
    <>
      <Card
        title={
          <div style={titleStyle}>
            <div style={queriesTitleStyle}>
              <Avatar src="https://img.icons8.com/plasticine/100/light-on.png" />
              {t('case_queries.title')}
            </div>
            <Button icon={<ExpandOutlined />} onClick={handleExpand} type="text">
              {screens.sm && t('case_queries.expand_label')}
            </Button>
          </div>
        }
      >
        <div style={{ height: '300px' }} data-testid="case-query-feed">
          {queryFeed && queryFeed.length > 0 ? (
            <CaseQueryFeed queryFeed={queryFeed} />
          ) : (
            renderEmptyState()
          )}
        </div>
        <CaseQueryInputArea
          query={query}
          setQuery={setQuery}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
        <CaseQuerySuggestedQuestions
          onQuestionClick={handleMainViewQuestionClick}
          maxSuggestions={2}
          casePresenter={casePresenter}
        />
      </Card>

      <ExpandableContainer isExpanded={isExpanded} onClose={handleCollapse}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div
            ref={feedRef}
            style={{ flex: 1, overflowY: 'auto' }}
            data-testid="case-query-feed"
          >
            {queryFeed && queryFeed.length > 0 ? (
              <CaseQueryFeed queryFeed={queryFeed} isExpanded={isExpanded} />
            ) : (
              renderEmptyState()
            )}
          </div>
          <div>
            <CaseQueryInputArea
              query={query}
              setQuery={setQuery}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
      </ExpandableContainer>
    </>
  );
});

export default React.memo(CaseQueries);
