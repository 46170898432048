import moment from 'moment';

class CaseTextEntryPresenter {
  constructor(caseTextEntryData) {
    this.uuid = caseTextEntryData.uuid;
    this.content = caseTextEntryData.content;
    this.completed = caseTextEntryData.completed;
    this.changeset = caseTextEntryData.changeset;
    this.problem = caseTextEntryData.problem;
  }

  getUUID() {
    return this.uuid;
  }

  getChangeset() {
    return this.changeset;
  }

  getCompletedAt(format = 'MMMM D, YYYY HH:mm:ss') {
    return moment(this.completedAt).format(format);
  }

  isCompleted() {
    return this.completed;
  }

  getProblem() {
    return this.problem;
  }

  hasProblem() {
    return !!this.problem;
  }
}

export default CaseTextEntryPresenter;
