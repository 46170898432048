import React, { useRef } from "react";
import { Typography, Button, Grid } from 'antd';
import { LoadingOutlined, CopyOutlined } from '@ant-design/icons';
import { toast } from "react-hot-toast";
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';
import ThumbsRating from "../ThumbsRating";
import DownloadPDFButton from '../../components/buttons/DownloadPDFButton';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const styles = {
  bubble: {
    padding: '10px',
    borderRadius: '10px',
  },
  question: {
    backgroundColor: '#e6f7ff',
    alignSelf: 'flex-end',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  answer: {
    backgroundColor: '#fff',
    alignSelf: 'flex-start',
  },
  copyButton: {
    marginTop: '0px'
  },
};

const CaseQueryBubble = ({ caseQueryUuid, isQuestion, content, completed }) => {
  const { t } = useTranslation();
  const contentRef = useRef();
  const screens = useBreakpoint();
  const bubbleStyle = {
    ...styles.bubble,
    ...(isQuestion ? styles.question : styles.answer),
    maxWidth: screens.xs ? '100%' : '90%',
  };

  const handleCopy = () => {
    logEvent('CaseQueryAnswer', 'Copy');
    navigator.clipboard.writeText(content).then(() => {
      toast.success(t('case_query_bubble.copied'));
    });
  };

  return (
    <div style={bubbleStyle}>
      <Text strong style={{ display: 'block', marginBottom: '2px' }}>
        {isQuestion ? t('case_query_bubble.me') : t('case_query_bubble.assistant')}
      </Text>
      {isQuestion ? (
        <Text>{content}</Text>
      ) : (
        <>
          <div ref={contentRef}>
            <ReactMarkdown components={{
              p: ({ node, ...props }) => <p style={{ marginTop: 0 }} {...props} />
            }}>{content}
            </ReactMarkdown>
          </div>
          {completed &&
            <div style={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '12px',}}>
              <Button
                type="default"
                icon={<CopyOutlined/>}
                onClick={handleCopy}
                style={styles.copyButton}
                data-testid="copy-answer-button"
              >
                {t('case_query_bubble.copy')}
              </Button>
              <DownloadPDFButton
                pdfRef={contentRef}
                eventCategory="CaseQueryAnswer"
              />
              <ThumbsRating
                rateableUuid={caseQueryUuid}
                rateableType="CaseQuery"
              />
            </div>
          }
        </>
      )}
      {!completed && <LoadingOutlined style={{ marginLeft: '10px' }} />}
    </div>
  );
};

export default CaseQueryBubble;
