import React from 'react';
import { Card, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import CaseDocumentsList from './CaseDocumentsList';
import CaseDocumentsDrop from './CaseDocumentsDrop';

const CaseDocuments = ({ caseData, caseUuid, onDocumentAttached }) => {
  const { t } = useTranslation();
  const documents = caseData.getDocuments();

  return (
    <Card title={t('case_documents.title')} style={{ margin: '0px 0' }}>
      {/* Drop area extracted into its own component */}
      <CaseDocumentsDrop caseUuid={caseUuid} onDocumentAttached={onDocumentAttached} />

      {/* Existing documents displayed via the extracted file list component */}
      <CaseDocumentsList documents={documents} />
    </Card>
  );
};

export default CaseDocuments;